import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
import {ServiceWorkerModule} from '@angular/service-worker'

import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {StoreModule} from '@ngrx/store'
import {state} from './store/FWMState'

import {BreadCrumbComponent} from './components/breadcrumb/breadcrumb.component'
import {HeadLineComponent} from './components/headline/headline.component'

import {EmbedWMDemoComponent} from './views/embed/embed.component'
import {ExtractComponent} from './views/extract/extract.component'
import {UploadContentComponent} from './views/upload/upload.component'
import {OverviewComponent} from './views/overview/overview.component'
import {HelpComponent} from './views/help/help.component'
import {OAuthModule, OAuthStorage} from "angular-oauth2-oidc";
import {OrganizationSwitcherComponent} from "./organization-switcher/organization-switcher.component";
import {AuthInterceptor} from "./auth.interceptor";
import {DndDirective} from "./dnd.directive";
import {OverviewPublicComponent} from "./views/overview-public/overview-public.component";


@NgModule({
  declarations: [
    AppComponent,
    EmbedWMDemoComponent,
    ExtractComponent,
    UploadContentComponent,
    BreadCrumbComponent,
    OverviewComponent,
    HelpComponent,
    HeadLineComponent,
    OrganizationSwitcherComponent,
    OverviewPublicComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DndDirective,
    OAuthModule.forRoot(),
    StoreModule.forRoot({state}),
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: true}),

  ],
  providers: [
    {
      provide: OAuthStorage,
      useValue: localStorage
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
