import {AuthConfig} from "angular-oauth2-oidc";
import {environment} from "../environments/environment";

export const authConfig: AuthConfig = {
  issuer: environment.idp_issuer,
  strictDiscoveryDocumentValidation: false,
  redirectUri: window.location.origin + '/',
  clientId: environment.client_id,
  responseType: 'code',
  scope: 'openid profile email',
  showDebugInformation: false,
  sessionChecksEnabled: true,
  timeoutFactor: 0.75,
  // disablePKCI: true,
  clearHashAfterLogin: false,
  userinfoEndpoint: environment.userinfo,
  logoutUrl: environment.logout_url,
};
