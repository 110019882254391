import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {OAuthService} from "angular-oauth2-oidc";
import {distinctUntilChanged, map, mergeMap, take} from "rxjs";
import {Injectable} from "@angular/core";
import {environment} from "../environments/environment";
import {State, Store} from "@ngrx/store";
import {FWMState} from "./store/FWMState";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: OAuthService, private store: Store<{ state: FWMState }>) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    return this.store.select('state').pipe(
      map(s => s?.organization.organization_urn),
      distinctUntilChanged(),
      mergeMap(org => {
        console.log('auth interceptor', org)
        const authToken = this.auth.getAccessToken();
        if (authToken &&
          !req.headers.has('Authorization') && (
            req.url.startsWith(window.location.protocol + '//' + window.location.host) || // absolute url (accessing via locally hosted frontend)
            req.url.startsWith('/') || // relative url (deployed)
            (environment.baseurl.length > 0 && req.url.startsWith(environment.baseurl))
          )
        ) {
          const authReq = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${authToken}` ).set('Organization', org)
          });

          return next.handle(authReq);
        } else {
          return next.handle(req);
        }

      }));

  }
}
