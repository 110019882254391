<section class="cl-section-regular">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-8">
        <p class="cl-p-large">
          Try our watermarking technology for yourself.
          First upload video or image content, then embed a watermark into it.
          Finally, experience how our extraction can identify the content source.
          <a class="cl-a-external" target="_blank" rel="noopener noreferrer" href="https://castlabs.com/image-watermarking/">Learn more</a>
        </p>
      </div>
    </div>
  </div>
</section>

<section class="cl-section-regular">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-4">
        <div class="card cl-card-secondary cl-card-max">
          <div class="card-header">
            Upload
          </div>
          <div class="card-body">
            <p>
              Optionally add an asset for watermark embedding.
            </p>
          </div>
          <div class="card-footer text-center">
            <a class="btn cl-btn-outline-primary" [routerLink]="['/', 'uploadcontent']" role="button">
              <span>Upload</span>
              <i class="fas fa-angle-right"></i>
            </a>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-4">
        <div class="card   cl-card-secondary cl-card-max">
          <div class="card-header">
            Embed
          </div>
          <div class="card-body">
            <p>
              Embed a watermark with custom parameters.
              You can either use your own uploaded content or one of our stock videos.
            </p>
          </div>
          <div class="card-footer text-center">
            <a class="btn cl-btn-outline-primary" [routerLink]="['/', 'embedwm']" role="button">
              <span>Embed</span>
              <i class="fas fa-angle-right"></i>
            </a>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-4">
        <div class="card cl-card-secondary cl-card-max">
          <div class="card-header">
            Extract
          </div>
          <div class="card-body">
            <p>
              Extract the embedded watermark with a single screenshot or camera photo.
            </p>
          </div>
          <div class="card-footer text-center">
            <a class="btn cl-btn-outline-primary" [routerLink]="['/', 'screencapture']" role="button">
              <span>Extract</span>
              <i class="fas fa-angle-right"></i>
            </a>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>
