<section class="cl-section-regular" *ngIf="!state.videoLink">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-8">
        <p class="cl-p-large">
          Our cloud service will embed a watermark into an image or into each frame of a video.
          Embedding will take a few seconds.
          Once completed, the watermarked content will be displayed and you can then take a
          screenshot or camera photo to use in the extraction phase.
        </p>
        <p class="alert cl-alert-info">
          A secret key is used for watermark access. For production this is unique per-customer.
          For this demo it's fixed as: castLabs-demo
        </p>
      </div>
    </div>
  </div>
</section>

<section class="cl-section-alternate" *ngIf="!state.videoLink">
  <form class="container cl-form" *ngIf="!state.processing">
    <div class="row">
      <div class="col-12">
        <div class="cl-tabs">
          <input class="cl-tab-1" type="radio" id="tab-1" name="tabs-1" checked>
          <input class="cl-tab-2" type="radio" id="tab-2" name="tabs-1">
          <ul>
            <li class="cl-tab-1"><label for="tab-1" (click)="setupNonBlind()">For non-blind extraction</label></li>
            <li class="cl-tab-2"><label for="tab-2" (click)="setupBlind()">For blind extraction</label></li>
          </ul>
          <div class="cl-tab-1 cl-tab-content">
            <p>
              Non-blind extraction is generally the most reliable and robust as there's a copy of unmarked source content available to compare an image against.
            </p>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <label for="t1_file_input" class="form-label">Source content</label>
                  <select id="t1_file_input" class="form-select" [(ngModel)]="content_name" [ngModelOptions]="{standalone: true}">
                    <option *ngFor="let f of files" [value]="f" [selected]="content_name === f">{{f}}</option>
                  </select>
                </div>
                <div class="col-12 col-md-4">
                  <label for="t1_profile_name_input" class="form-label">Use-case template</label>
                  <select id="t1_profile_name_input" class="form-select" [(ngModel)]="profile_idx"
                          [ngModelOptions]="{standalone: true}" (change)="onWatermarkProfileSelectionChange($event)">
                    <option *ngFor="let p of profile_name" [value]="p.id" [selected]="profile_idx === p.id">
                      {{ p.name }} ({{ p.type }})
                    </option>
                  </select>
                </div>
                <div class="col-12 col-md-4">
                  <label for="t1_bit-profile" class="form-label">Number of bits embedded</label>
                  <select name="t1_bit-profile" id="t1_bit-profile" class="form-select" [(ngModel)]="state.bit_profile"
                          [ngModelOptions]="{standalone: true}" (change)="onBitProfileSelectionChange($event)">
                    <option *ngFor="let b of bit_profiles" [value]="b.id" [selected]="state.bit_profile === b.id">{{b.name}}</option>
                  </select>
                </div>
                <div class="col-12 col-md-4">
                  <label for="t1_wm-id" class="form-label">Watermark ID (0-{{wm_id_max}})</label>
                  <input id="t1_wm-id" type="text" [formControl]="wmidInput" pattern="^[0-9a-fA-F]+$" min="0" placeholder="0"
                         [(ngModel)]="wm_id" (input)="limitInputValue()" required>
                </div>

                <div class="col-12 col-md-4">
                  <label for="t1_wm_strength_input" class="form-label">Watermark strength</label>
                  <select id="t1_wm_strength_input" class="form-select" [(ngModel)]="state.wm_strength"
                          [ngModelOptions]="{standalone: true}" [disabled]="profile_idx !== '2'">
                    <option *ngFor="let s of strengths" [value]="s.id" [selected]="state.wm_strength === s.id">{{s.name}}</option>
                  </select>
                </div>
                <div class="col-12 col-md-4">
                  <label for="t1_sp-density" class="form-label">Superpixel density</label>
                  <select id="t1_sp-density" class="form-select" [(ngModel)]="state.sp_density" [ngModelOptions]="{standalone: true}" [disabled]="profile_idx !== '2'">
                    <option *ngFor="let d of densities" [value]="d.id" [selected]="state.sp_density === d.id">{{d.name}}</option>
                  </select>
                </div>
                <div class="col-12 col-md-4">
                  <label for="t1_pixel-density" class="form-label">Pixel density</label>
                  <select name="t1_pixel-density" id="t1_pixel-density" class="form-select" [(ngModel)]="state.pixel_density"
                          [ngModelOptions]="{standalone: true}" [disabled]="profile_idx !== '2'">
                    <option *ngFor="let d of densities" [value]="d.id" [selected]="state.pixel_density === d.id">{{d.name}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="cl-tab-2 cl-tab-content">
            <p>
              Blind extraction covers use cases where there's no available copy of unmarked source content (for example with real-time communication).
            </p>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <label for="t2_file_input" class="form-label">Source content</label>
                  <select id="t2_file_input" class="form-select" [(ngModel)]="content_name" [ngModelOptions]="{standalone: true}">
                    <option *ngFor="let f of files" [value]="f" [selected]="content_name === f">{{f}}</option>
                  </select>
                </div>
                <div class="col-12 col-md-6">
                  <label for="t2_bit-profile" class="form-label">Number of bits embedded</label>
                  <select name="t2_bit-profile" id="t2_bit-profile" class="form-select" [(ngModel)]="state.bit_profile"
                          [ngModelOptions]="{standalone: true}" (change)="onBitProfileSelectionChange($event)">
                    <option *ngFor="let b of bit_profiles" [value]="b.id" [selected]="state.bit_profile === b.id">{{b.name}}</option>
                  </select>
                </div>
                <div class="col-12 col-md-6">
                  <label for="t2_wm-id" class="form-label">Watermark ID (0-{{wm_id_max}})</label>
                  <input id="t2_wm-id" type="text" [formControl]="wmidInput" pattern="^[0-9a-fA-F]+$" min="0" placeholder="0"
                         [(ngModel)]="wm_id" (input)="limitInputValue()" required>
                </div>

                <div class="col-12 col-md-4">
                  <label for="t2_wm_strength_input" class="form-label">Watermark strength</label>
                  <select id="t2_wm_strength_input" class="form-select" [(ngModel)]="state.wm_strength"
                          [ngModelOptions]="{standalone: true}">
                    <option *ngFor="let s of strengths" [value]="s.id" [selected]="state.wm_strength === s.id">{{s.name}}</option>
                  </select>
                </div>
                <div class="col-12 col-md-4">
                  <label for="t2_sp-density" class="form-label">Superpixel density</label>
                  <select id="t2_sp-density" class="form-select" [(ngModel)]="state.sp_density" [ngModelOptions]="{standalone: true}">
                    <option *ngFor="let d of densities" [value]="d.id" [selected]="state.sp_density === d.id">{{d.name}}</option>
                  </select>
                </div>
                <div class="col-12 col-md-4">
                  <label for="t2_pixel-density" class="form-label">Pixel density</label>
                  <select name="t2_pixel-density" id="t2_pixel-density" class="form-select" [(ngModel)]="state.pixel_density"
                          [ngModelOptions]="{standalone: true}">
                    <option *ngFor="let d of densities" [value]="d.id" [selected]="state.pixel_density === d.id">{{d.name}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="container" *ngIf="state.processing">
    <div class="row">
      <div class="col-12 text-center">
        <div class="cl-spinner">Processing ...<i class="fas fa-spinner fa-spin"></i></div>
      </div>
    </div>
  </div>
</section>

<section class="cl-section-regular" *ngIf="!state.videoLink && !state.processing">
  <div class="container">
    <div class="row">
      <div class="col-12 text-end">
        <button class="btn cl-btn-outline-primary" [class.cl-btn-spinner]="state.processing" role="button" (click)="processFile()">
          <span>Embed watermark</span>
          <i class="fas fa-angle-right"></i>
        </button>
      </div>
    </div>
  </div>
</section>

<!-- ---------------------------------------------------------------------------- -->

<section class="cl-section-regular" *ngIf="state.videoLink && !state.error">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-8">
        <p class="cl-p-large">
          You can now view the watermarked content and take a screenshot or phone photo
          to use in the <a [routerLink]="['/', 'screencapture']">extraction phase</a>.
          Please remember the embedded settings below as they'll need to be used for extraction.
        </p>
        <p class="alert cl-alert-danger">
          <strong>Embedding template ({{ getProfileName() }}):</strong>
          ID: {{ state.wm_id }}
          •
          Strength: {{ state.wm_strength }}
          •
          Superpixel density: {{ state.sp_density }}
        </p>
        <p class="alert cl-alert-info">
          Content: {{ content_name }}
          •
          Extraction method: {{ method }}
          •
          Bits {{ state.bit_profile }}
        </p>
        <p class="alert cl-version-info">
          Embedder version: {{ state.version }}
        </p>
      </div>
      <div class="col-12 col-lg-4">
        <div class="cl-flex-center-center">
          <button class="btn btn-sm cl-btn-outline-text" role="button" (click)="restart()">
            <span>Start all over</span>
            <i class="fas fa-redo-alt"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="cl-section-regular" *ngIf="state.videoLink && !state.error">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <video *ngIf="!state.isImage" controls #videoPlayer style="width: 100%">
          <source [src]="state.videoLink" type="video/mp4"/>
          Browser not supported
        </video>
        <img *ngIf="state.isImage && !state.is_tiff" [src]="state.videoLink"/>
        <p *ngIf="state.is_tiff" class="cl-p-large"> A Tiff image can't be displayed. You can download with the link below. </p>
        <div class="cl-spacing-box text-end">
          <a class="btn btn-sm cl-btn-outline-primary" [href]="state.videoLink">Download content</a>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- ---------------------------------------------------------------------------- -->

<section class="cl-section-regular" *ngIf="state.error">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-8">
        <h2>Error</h2>
        <p>{{ state.error }} </p>
      </div>
      <div class="col-12 col-lg-4">
        <div class="cl-flex-center-center">
          <button class="btn btn-sm cl-btn-outline-text" role="button" (click)="restart()">
            <span>New embed</span>
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
