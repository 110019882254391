import {SwUpdate} from "@angular/service-worker";
import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, ReplaySubject} from "rxjs";
import {Subject} from "rxjs/internal/Subject";

@Injectable({providedIn: 'root'})
export class UpdateService {

  available = new Subject<{
    branch: string,
    commitDate: string,
    buildDate: string,
    isDirty: string,
    commit: string,
  }>();


  constructor(updates: SwUpdate) {
    console.log('UpdateService')
    updates.versionUpdates.subscribe(evt => {
      console.log('UpdateService: ', evt)
      switch (evt.type) {
        case 'VERSION_READY':
          if (evt.latestVersion.appData) {
            const appData = evt.latestVersion.appData as {
              branch: string,
              commitDate: string,
              buildDate: string,
              isDirty: string,
              commit: string,
            }
            this.available.next(appData);
          }
          break;
      }
    });

  }
}
