import {AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core'
import {S3} from '@aws-sdk/client-s3'
import {AppConfigService} from '../../app-config.service'
import {mergeMap, Observable, skipWhile, takeUntil, timer} from 'rxjs'
import {animate, state, style, transition, trigger} from '@angular/animations'
import {HttpClient} from '@angular/common/http'
import {environment} from '../../../environments/environment'
import {Subject} from 'rxjs/internal/Subject'
import {ActivatedRoute} from '@angular/router'
import {findCornerPoints} from './line-detector'

import {Store} from '@ngrx/store'

import * as UI from '@castlabs/ui/src/utils/ui.js'
import {FWMState, initialState, set, StateUpdate} from "../../store/FWMState";

@Component({
  selector: 'app-extract',
  templateUrl: './extract.component.html',
  styleUrls: ['./extract.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('growshrink', [
      state(
        'void',
        style({
          opacity: 0,
          height: '0',
          padding: '0',
          margin: '0',
          border: '0',
          transform: 'matrix(0.0001,0,0,0.0001,-400,0)'
        })
      ),
      // element being added into DOM.
      transition(':enter', [
        animate(
          '300ms ease-in-out',
          style({
            opacity: '*',
            height: '*',
            padding: '*',
            margin: '*',
            border: '*',
            transform: 'matrix(1,0,0,1, 0,0)'
          })
        )
      ]),
      // element being removed from DOM.
      transition(':leave', [
        animate(
          '300ms ease-in-out',
          style({
            opacity: 0,
            height: '0',
            padding: '0',
            margin: '0',
            border: '0',
            transform: 'matrix(0.0001,0,0,0.0001,400,0)'
          })
        )
      ])
    ])]
})
export class ExtractComponent implements OnInit, AfterViewInit {
  s3: S3;
  key!: string;
  bucket!: string;
  current_operation = 'Initialization'
  @ViewChild('canvasCap') canvasCap!: ElementRef
  @ViewChild('canvasRec') canvasRec!: ElementRef
  @ViewChildren('canvasCap') canvasesCap!: QueryList<ElementRef<HTMLCanvasElement>>
  @ViewChildren('canvasRec') canvasesRec!: QueryList<ElementRef<HTMLCanvasElement>>
  private contextCap!: CanvasRenderingContext2D
  private contextRec!: CanvasRenderingContext2D

  bit_profiles = [{
    id: '8',
    name: '8',
    max: 'ff'
  },
    {
      id: '13',
      name: '13',
      max: '1fff'
    },
    {
      id: '16',
      name: '16',
      max: 'ffff'
    },
    {
      id: '24',
      name: '24',
      max: 'fffff'
    },
    {
      id: '32',
      name: '32',
      max: 'ffffffff'
    }
  ]

  subtracts = [
    {
      id: 'Subtraction',
      name: 'Subtraction'
    },
    {
      id: 'Capture',
      name: 'Capture'
    }
  ]

  files: string[] = []
  strengths = [
    /*    {
        'id': '1',
        'name': '1'
      },
      */

    // {
    //   id: '2',
    //   name: '2'
    // },
    {
      id: '3',
      name: '3'
    },

    {
      id: '4',
      name: '4'
    },

    {
      id: '6',
      name: '6 (suitable for blind extraction)'
    },

    {
      id: '8',
      name: '8 (suitable for blind extraction)'
    }

    /* {
      'id': '5',
      'name': '5'
    } */
  ]

  densities = [{
    id: '10',
    name: '10'
  },
    {
      id: '20',
      name: '20'
    },
    {
      id: '25',
      name: '25'
    },
    {
      id: '30',
      name: '30'
    },
    {
      id: '35',
      name: '35'
    },
    {
      id: '40',
      name: '40'
    },
    {
      id: '45',
      name: '45'
    },
    {
      id: '50',
      name: '50'
    },
    {
      id: '60',
      name: '60'
    },
    {
      id: '70',
      name: '70'
    },
    {
      id: '90',
      name: '90'
    },
    {
      id: '100',
      name: '100'
    }
  ]

  profiles = [
    {wm_strength: '4', sp_density: '30'},
    {wm_strength: '4', sp_density: '60'},
    {wm_strength: '4', sp_density: '50'}
  ]

  profile_name = [
    {
      id: '0',
      name: 'Maintain high quality (pre-set)'
    },
    {
      id: '1',
      name: 'Social media leak (pre-set)'
    },
    {
      id: '2',
      name: 'Custom (set during embedding)'
    }
  ]

  blind = false
  profile_idx = '2'
  wm_strength = this.profiles[1].wm_strength
  sp_density = this.profiles[1].sp_density
  content_name = '[Select content]' // 'hockey'
  content_name_none = '[Select content]'
  subtract = 'Subtraction'
  bit_profile = '13'
  search_range = 0.5
  search_step = 1
  refinement_steps = 1
  cornerPointsInt = new Array(8)
  xStep = 1
  yStep = 1
  takeDownRunning = false
  resetRunning = false
  luma!: Uint8Array
  luma_width!: number
  luma_height!: number
  lumaRead = false

  state = initialState.extract
  organization: string = initialState.organization.organization_urn;

  blockTri: Observable<{ tri: string }[]>

  constructor(
    private appConfig: AppConfigService,
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    private store: Store<{ state: FWMState }>,
  ) {
    this.s3 = new S3({
      credentials: appConfig.credentials(),
      region: 'us-east-1'
    })
    this.blockTri = timer(0, 1000).pipe(
      mergeMap(() => this.httpClient.get<{ tri: string }[]>(`${environment.baseurl}/getall`))
    )
  }

  ngAfterViewInit() {
    this.canvasesCap.changes.subscribe((canvasList: QueryList<ElementRef<HTMLCanvasElement>>) => {
      if (canvasList.length) {
        this.contextCap = (this.canvasCap.nativeElement as HTMLCanvasElement).getContext('2d')!
        this.drawCap()
      }
    })
    this.canvasesRec.changes.subscribe((canvasList: QueryList<ElementRef<HTMLCanvasElement>>) => {
      if (canvasList.length) {
        this.contextRec = (this.canvasRec.nativeElement as HTMLCanvasElement).getContext('2d')!
        this.drawRec()
      }
    })
    if (this.state.step === 5) {
      this.convert_string_to_pts()
      this.contextCap = (this.canvasCap.nativeElement as HTMLCanvasElement).getContext('2d')!
      this.contextRec = (this.canvasRec.nativeElement as HTMLCanvasElement).getContext('2d')!
      this.drawRec()
      this.drawCap()
    }
  }

  onWatermarkProfileSelectionChange(event: Event) {
    const selectedId = (<HTMLSelectElement>event.target).value
    const selectedProfile = this.profile_name.find(b => b.id === selectedId)
    if (selectedProfile) {
      this.wm_strength = this.profiles[parseInt(selectedProfile.id, 10)].wm_strength
      this.sp_density = this.profiles[parseInt(selectedProfile.id, 10)].sp_density
    }
  }

  updateState(data: StateUpdate['extract']): void {
    this.store.dispatch(set({extract: data}))
  }

  restart(): void {
    this.updateState(initialState.extract)
    this.updateState({
      result: undefined,
      rectifiedFrame: undefined,
      error: undefined
    })
  }

  setupNonBlind() {
    this.blind = false
    this.profile_idx = '2'
    this.wm_strength = '4'
    this.sp_density = '60'
  }

  setupBlind() {
    this.blind = true
    this.wm_strength = '6'
    this.sp_density = '100'
  }

  canUpload(): boolean {
    if (this.blind) return true
    if (this.content_name !== this.content_name_none) return true
    return false
  }

  private drawCap() {
    const context = this.contextCap
    const canvas = this.canvasCap.nativeElement as HTMLCanvasElement
    const x = 0
    const y = 0
    const img = new Image()
    const ptsInt = new Array(8)
    const selected = new Array(4)
    selected[0] = selected[1] = selected[2] = selected[3] = false
    for (let i = 0; i < 8; i++) {
      ptsInt[i] = this.cornerPointsInt[i]
    }
    img.crossOrigin = 'Anonymous'
    img.src = "https://" + this.state.capturedFrame
    console.log("sdfgsdgvsadfgv")
    console.log(img.src)

    const self = this // eslint-disable-line @typescript-eslint/no-this-alias

    function drawPoint(x: number, y: number, ptX: number, ptY: number, context: CanvasRenderingContext2D, color: string) {
      context.strokeStyle = color
      context.lineWidth = 5
      context.beginPath()
      context.arc(ptX, ptY, 25, 0, 2 * Math.PI)
      context.stroke()
      context.closePath()
      context.beginPath()
      context.arc(ptX, ptY, 5, 0, 2 * Math.PI)
      context.stroke()
      context.closePath()
    }

    function drawLine(x: number, y: number, ptX: number, ptY: number, context: CanvasRenderingContext2D, color: string) {
      context.strokeStyle = color
      context.lineWidth = 5
      context.beginPath()
      context.moveTo(x, y)
      context.lineTo(ptX, ptY)
      context.stroke()
      context.closePath()
    }

    function drawImagePoints(ptsInt: number[], context: CanvasRenderingContext2D, color: string) {
      context.drawImage(img, 0, 0)
      if (!self.lumaRead) {
        const rgb = context.getImageData(0, 0, img.width, img.height)
        self.luma = new Uint8Array(img.width * img.height)
        self.luma_width = img.width
        self.luma_height = img.height
        for (let i = 0; i < rgb.data.length; i += 4) {
          const r = rgb.data[i]
          const g = rgb.data[i + 1]
          const b = rgb.data[i + 2]

          // BT.709 conversion coeffs
          const pixLuma = 0.2126 * r + 0.7152 * g + 0.0722 * b
          self.luma[i / 4] = toUint8(pixLuma)
        }
        self.lumaRead = true
      }
      context.strokeStyle = color
      for (let i = 0; i < ptsInt.length; i += 2) {
        drawPoint(x, y, ptsInt[i], ptsInt[i + 1], context, 'red')
      }
      drawLine(ptsInt[0], ptsInt[1], ptsInt[2], ptsInt[3], context, 'red')
      drawLine(ptsInt[2], ptsInt[3], ptsInt[6], ptsInt[7], context, 'red')
      drawLine(ptsInt[6], ptsInt[7], ptsInt[4], ptsInt[5], context, 'red')
      drawLine(ptsInt[4], ptsInt[5], ptsInt[0], ptsInt[1], context, 'red')
    }

    function toUint8(x: number) {
      return x < 0 ? 0 : x > 255 ? 255 : x | 0
    }

    img.onload = function () {
      canvas.width = img.width
      canvas.height = img.height
      drawImagePoints(ptsInt, context, 'red')
    }

    canvas.addEventListener('click', (event: MouseEvent) => {
      const rect = canvas.getBoundingClientRect()
      const x = (event.clientX - rect.left) * img.width / canvas.clientWidth
      const y = (event.clientY - rect.top) * img.height / canvas.clientHeight
      if (selected.every(value => value === false)) {
        for (let i = 0; i < ptsInt.length; i += 2) {
          if (x > ptsInt[i] - 25 && x < ptsInt[i] + 25 && y > ptsInt[i + 1] - 25 && y < ptsInt[i + 1] + 25) {
            drawPoint(x, y, ptsInt[i], ptsInt[i + 1], context, 'yellow')
            selected[i / 2] = true
            break
          }
        }
      } else {
        for (let i = 0; i < 4; i += 1) {
          if (selected[i]) {
            context.clearRect(this.cornerPointsInt[i * 2] - 25, this.cornerPointsInt[i * 2 + 1] - 25, 50, 50)
            ptsInt[i * 2] = this.cornerPointsInt[i * 2] = Math.floor(x)
            ptsInt[i * 2 + 1] = this.cornerPointsInt[i * 2 + 1] = Math.floor(y)

            selected[i] = false
            drawImagePoints(ptsInt, context, 'red')
          }
        }
      }
    })
  }

  private drawRec() {
    if (!this.canvasRec) return

    const context = this.contextRec
    const canvas = this.canvasRec.nativeElement as HTMLCanvasElement
    const img = new Image()
    img.src = "https://" + this.state.rectifiedFrame
    console.log(img.src)

    img.onload = function () {
      canvas.width = img.width
      canvas.height = img.height
      context.drawImage(img, 0, 0)
    }
  }

  onChange() {
    this.drawCap()
  }

  ngOnInit(): void {
    this.store.select('state').subscribe(data => {
      this.state = data.extract
      this.organization = data.organization.organization_urn
    })
    if (this.state.step !== 5) this.restart()
    this.httpClient.get<{
      'status': string,
      'content_list': string,
    }>(`${environment.baseurl}/get_content_list`).subscribe({
      next: (res) => {
        const contentList = res.content_list.split(';').map(String)
        this.files = [this.content_name_none]
        for (let i = 0; i < contentList.length; i++) { // remove last 'no reference' too
          this.files.push(contentList[i])
        }
      }
    })
    this.route.queryParams.subscribe(params => {
      if (params.content_name) {
        if (params.content_name === 'no reference') {
          this.setupBlind()
        } else {
          this.content_name = params.content_name
          this.setupNonBlind()
        }
      }
      if (params.wm_strength) {
        this.wm_strength = params.wm_strength
      }
      if (params.sp_density) {
        this.sp_density = params.sp_density
      }
      if (params.bit_profile) {
        this.bit_profile = params.bit_profile
      }
    })
  }

  convert_string_to_pts() {
    const ptsStr = this.state.cornerPoints?.match(/-?\d+/g)
    const ptsInt = ptsStr ? ptsStr.map(Number) : []

    for (let i = 0; i < ptsInt.length; i++) {
      if (ptsInt[i] < 0) {
        this.cornerPointsInt[i] = 0
      } else {
        this.cornerPointsInt[i] = ptsInt[i]
      }
    }
  }

  displayStep() {
    console.log('step')
    console.log(this.state.step)
    console.log(this.state.frameNumber)
    console.log(this.state.result)
    console.log(this.state.rectifiedFrame)
    console.log(this.state.error)
  }

  alignBorders() {
    this.updateState({processing: true})
    let corners = new Array(4).fill({cx: -1, cy: -1, x: -1, y: -1}, 0, 4)

    this.lumaRead = false

    corners = [
      {x: this.cornerPointsInt[0], y: this.cornerPointsInt[1]},
      {x: this.cornerPointsInt[2], y: this.cornerPointsInt[3]},
      {x: this.cornerPointsInt[6], y: this.cornerPointsInt[7]},
      {x: this.cornerPointsInt[4], y: this.cornerPointsInt[5]}
    ]

    const refinedCorners = findCornerPoints(this.luma, this.luma_width, this.luma_height, corners)
    this.cornerPointsInt = [
      refinedCorners[0].x, refinedCorners[0].y,
      refinedCorners[1].x, refinedCorners[1].y,
      refinedCorners[3].x, refinedCorners[3].y,
      refinedCorners[2].x, refinedCorners[2].y
    ]

    this.drawCap()
    this.updateState({processing: false})
  }

  refineExtraction() {

    this.updateState({processing: true})
    const json = {
      organization: this.organization,
      strength: this.wm_strength,
      content_name: this.blind ? 'no reference' : this.content_name,
      corner_pts: this.cornerPointsInt.join(' '),
      search_range: this.search_range,
      search_step: this.search_step,
      refinement_steps: this.refinement_steps,
      subtract: this.subtract,
      sp_density: this.sp_density,
      process_id: this.state.processID,
      best_frame: this.state.frameNumber,
      bit_profile: this.bit_profile,
      key: this.key,
      bucket: this.bucket
    }

    const stopPolling$ = new Subject()
    this.httpClient.post<string>(`${environment.baseurl}/refine`, json).pipe().subscribe({
      next: (res) => {
        timer(1000, 1000).pipe(
          takeUntil(stopPolling$),
          mergeMap(() => this.httpClient.get<{
            'adv_stats': string,
            'aligned_path': string,
            'confidence': string
            'corrections': string,
            'current_operation': string,
            'error_cause': string,
            'min_value_numeric': number,
            'min_value_text': string,
            'min_value': string,
            'parameters': string,
            'pts': string,
            'result': string,
            'start_date': string,
            'status': string,
            'stop_date': string
            'version': string,
            'wm_id_bin': string,
            'wm_id_dec': string,
            'wm_id_hex': string,
            'wm_id': string,
          }>(`${environment.baseurl}/refine/${res}`)),
          skipWhile(res2 => res2.status === 'STOPPED')
        ).subscribe({
          next: (res3) => {
            if (res3.status === 'SUCCEEDED') {
              stopPolling$.next(true)
              const parameters = JSON.parse(res3.parameters ?? '{}')
              this.updateState({
                adv_stats: res3.adv_stats === 'true',
                confidence: res3.confidence,
                corrections: res3.corrections,
                min_value_numeric: res3.min_value_numeric,
                min_value_text: res3.min_value_text,
                min_value: res3.min_value,
                processing: false,
                rectifiedFrame: res3.aligned_path,
                result: '',
                start_date: res3.start_date,
                stop_date: res3.stop_date,
                version: res3.version,
                wm_id_bin: res3.wm_id_bin,
                wm_id_dec: res3.wm_id_dec,
                wm_id_hex: res3.wm_id_hex,
                wm_strength: parameters.strength,
                bit_profile: parameters.bit_profile,
                sp_density: parameters.sp_density,

              })
              this.cornerPointsInt = res3.pts.split(' ').map(Number)
              this.drawRec()
              this.drawCap()
              this.current_operation = 'Finished'
              window.scrollTo(0, 0)
              console.log('itop1')
            } else if (res3.status === 'RUNNING') {
              this.current_operation = res3.current_operation
            } else if (res3.status === 'FAILED') {
              this.updateState({
                processing: false,
                error: res3.error_cause
              })
              stopPolling$.next(true)
              this.current_operation = 'Failed'
              window.scrollTo(0, 0)
              console.log('itop2')
            }
          }
        })
      },
      error: () => {
        this.updateState({processing: false})
      }
    })
  }

  onFileDropped(files: File[]) {
    for (const file of files) {
      this.processFile(file);
    }
  }

  onFileSelected(event: Event) {
    const input = event.currentTarget as HTMLInputElement;
    for (const file of Array.from(input.files || [])) {
      this.processFile(file);
    }
  }


  processFile(file: File) {
    this.updateState({
      processing: true,
      step: 1,
      frameNumber: undefined,
      result: undefined,
      rectifiedFrame: undefined
    })

    const ext = file.name.split('.').pop()
    const stem = file.name.split('.').slice(0, -1).join('.')
    const uuid = crypto.randomUUID()
    this.key = this.organization + '/' + uuid + '/' + stem + '.' + ext
    this.bucket = this.appConfig.config.Bucket
    const params = {
      Key: this.key,
      Body: file,
      Bucket: this.bucket
    }
    this.s3.putObject(params).then(() => {

    }).catch(reason => {
      this.updateState({error: reason})
    })
    const json = {
      organization: this.organization,
      strength: this.wm_strength,
      content_name: this.blind ? 'no reference' : this.content_name,
      subtract: this.subtract,
      sp_density: this.sp_density,
      bit_profile: this.bit_profile,
      key: this.key,
      bucket: this.bucket
    }
    const stopPolling$ = new Subject()
    this.httpClient.post<string>(`${environment.baseurl}/extract`, json).pipe().subscribe({
      next: (res) => {
        timer(1000, 1000).pipe(
          takeUntil(stopPolling$),
          mergeMap(() => this.httpClient.get<{
            'adv_stats': string,
            'aligned_path': string,
            'captured_path': string,
            'confidence': string
            'corner_points': string,
            'corrections': string,
            'current_operation': string,
            'error_cause': string,
            'frame_number': number,
            'min_value_numeric': number,
            'min_value_text': string,
            'min_value': string,
            'parameters': string,
            'process_id': string
            'result': string,
            'start_date': string,
            'status': string,
            'stop_date': string,
            'version': string,
            'wm_id_bin': string,
            'wm_id_dec': string,
            'wm_id_hex': string,
          }>(`${environment.baseurl}/extract/${res}`)),
          skipWhile(res2 => res2.status === 'STOPPED')
        ).subscribe({
          next: (res3) => {
            if (res3.status === 'SUCCEEDED') {
              stopPolling$.next(true)
              const parameters = JSON.parse(res3.parameters ?? '{}')
              this.updateState({
                adv_stats: res3.adv_stats === 'true',
                capturedFrame: res3.captured_path,
                confidence: res3.confidence,
                cornerPoints: res3.corner_points,
                corrections: res3.corrections,
                frameNumber: res3.frame_number,
                min_value_numeric: res3.min_value_numeric,
                min_value_text: res3.min_value_text,
                min_value: res3.min_value,
                processID: res3.process_id,
                processing: false,
                rectifiedFrame: res3.aligned_path,
                result: res3.result,
                start_date: res3.start_date,
                step: 5,
                stop_date: res3.stop_date,
                version: res3.version,
                wm_id_bin: res3.wm_id_bin,
                wm_id_dec: res3.wm_id_dec,
                wm_id_hex: res3.wm_id_hex,
                wm_strength: parameters.strength,
                bit_profile: parameters.bit_profile,
                sp_density: parameters.sp_density,
              })
              if (this.state.frameNumber === 0) {
                this.updateState({frameNumber: 1})
              }

              this.convert_string_to_pts()
              this.drawRec()
              this.current_operation = 'Finished'
            } else if (res3.status === 'RUNNING') {
              this.current_operation = res3.current_operation
              switch (this.current_operation) {
                case 'Uploading image':
                  this.updateState({step: 2})
                  break
                case 'Searching for the reference frame':
                  this.updateState({step: 3})
                  break
                case 'Refining extraction parameters':
                  this.updateState({step: 4})
                  break
                case 'Reviewing the results of the extraction':
                  this.updateState({step: 5})
                  break
              }
            } else if (res3.status === 'FAILED') {
              stopPolling$.next(true)
              console.log(res3.error_cause)
              this.updateState({
                processing: false,
                error: res3.error_cause,
                step: 5
              })
              this.current_operation = 'Failed'
            }
          }
        })
      },
      error: err => {
        console.log(err)
        this.updateState({processing: false})
      }
    })
  }

  resetDemo(tri: string) {
    this.resetRunning = true
    this.httpClient.post(`${environment.baseurl}/reset`, {tri}).subscribe(() => {
    }, error1 => {
      console.log(error1)
      alert(error1.message)
      this.resetRunning = false
    }, () => {
      this.resetRunning = false
    })
  }

  takedown() {
    this.takeDownRunning = true
    this.httpClient.post(`${environment.baseurl}/takedown`, {
      WM_ID: this.state.wm_id_dec
    }).subscribe(() => {
    }, error1 => {
      alert(error1)
    }, () => {
      this.takeDownRunning = false
    })
  }

  getStartDate() {
    return UI.clFormatDateTimeUTC(new Date(this.state.start_date ?? 0))
  }

  getDuration() {
    return UI.clFormatDuration(new Date(this.state.start_date ?? 0), new Date(this.state.stop_date ?? 0))
  }

  reload() {
    location.reload()
  }

  isNoReferenceFrameFound(): boolean {
    return !!this.state.error && this.state.error.toLowerCase().indexOf('no reference frame found') >= 0
  }

  protected readonly environment = environment
}
