'use strict'

//export {Point, LineSegment, Line, detectLine, detectLineTests, findCornerPoints}

const LEFT = 0, RIGHT = 1, TOP = 2, BOTTOM = 3
const TOP_LEFT = 0, TOP_RIGHT = 1, BOTTOM_RIGHT = 2, BOTTOM_LEFT = 3

export class Point {
  constructor(x = 0, y = 0) {
    this.x = x
    this.y = y
  }
}

class LineSegment {
    constructor(p1, p2) {
        this.p1 = p1
        this.p2 = p2
    }

    length2() {
        const dx = this.p2.x - this.p1.x
        const dy = this.p2.y - this.p1.y
        return dx * dx + dy * dy
    }

    length() {
        return Math.sqrt(this.length2())
    }
}

class Line {
    constructor(a = 0, b = 0, c = 0) {
        this.a = a
        this.b = b
        this.c = c
    }

    static fromPoints(p1, p2) {
        const dx = p2.x - p1.x
        const dy = p2.y - p1.y
        const a = -dy
        const b = dx
        const c = -a * p1.x - b * p1.y
        return new Line(a, b, c)
    }

    getY(x) {
        return (-this.c - this.a * x) / this.b
    }
    getX(y) {
        return (-this.c - this.b * y) / this.a
    }

    // get 2 points for scatter plots and such. Handles vertical and horizontal lines
    toLineSegment() {
        if (Math.abs(this.b) > Math.abs(this.a))
            return new LineSegment({x: -10, y: this.getY(-10)}, {x: 10, y: this.getY(10)})

        return new LineSegment({x: this.getX(-10), y: -10}, {x: this.getX(10), y: 10})
    }

    normalize() {
        const maxab = Math.max(Math.abs(this.a), Math.abs(this.b))
        const norm = (this.a < 0 ? -1 : 1) / maxab
        this.a *= norm
        this.b *= norm
        this.c *= norm
    }
}


//
// distance/bounding box utils
//

function pointPointDist2(p1, p2) {
    const dx = p2.x - p1.x
    const dy = p2.y - p1.y
    return dx * dx + dy * dy
}

function pointLineSegmentDist2(point, line) {
    const x0 = point.x
    const y0 = point.y
    const x1 = line.p1.x
    const y1 = line.p1.y
    const x2 = line.p2.x
    const y2 = line.p2.y

    const dx = x2 - x1
    const dy = y2 - y1

    if (dx === 0 && dy === 0) {
        // the line segment is just a point, so return the squared distance to that point
        const dx0 = x0 - x1
        const dy0 = y0 - y1
        return dx0 * dx0 + dy0 * dy0
    }

    // calculate the parameter t at which the projection of the point onto the line falls
    const t = ((x0 - x1) * dx + (y0 - y1) * dy) / (dx * dx + dy * dy)

    if (t <= 0) {
        // the closest point on the line is the first endpoint
        const dx0 = x0 - x1
        const dy0 = y0 - y1
        return dx0 * dx0 + dy0 * dy0
    }
    else if (t >= 1) {
        // the closest point on the line is the second endpoint
        const dx0 = x0 - x2
        const dy0 = y0 - y2
        return dx0 * dx0 + dy0 * dy0
    }

    // the closest point on the line is between the endpoints
    const xProj = x1 + t * dx
    const yProj = y1 + t * dy
    const dx0 = x0 - xProj
    const dy0 = y0 - yProj
    return dx0 * dx0 + dy0 * dy0
}

function pointLineDist2(point, line) {
    const num = line.a * point.x + line.b * point.y + line.c
    return (num * num) / (line.a * line.a + line.b * line.b)
}

function findBoundingBox(points) {
    let xmin = points[0].x
    let xmax = xmin
    let ymin = points[0].y
    let ymax = ymin
    for (let i = 1; i < points.length; ++i) {
        const x = points[i].x
        const y = points[i].y
        if (x < xmin) xmin = x
        if (x > xmax) xmax = x
        if (y < ymin) ymin = y
        if (y > ymax) ymax = y
    }
    return [{x: xmin, y: ymin}, {x: xmax, y: ymax}]
}

function findIntersection(line1, line2) {
    const cross = line1.a * line2.b - line2.a * line1.b
    if (Math.abs(cross) < 1e-9)
        return null

    const x = (line2.c * line1.b - line1.c * line2.b) / cross
    const y = (line2.a * line1.c - line1.a * line2.c) / cross
    return new Point(x, y)
}

//
// distance/bounding box utils end
//

function calculateCandidateMatch(line, points, minMatchDist2) {
    let pointsDist2Sorted = []
    points.forEach((p) => {
        pointsDist2Sorted.push({dist2: pointLineDist2(p, line), x: p.x, y: p.y})
    })
    pointsDist2Sorted.sort((a, b) => a.dist2 - b.dist2)

    let xmin = pointsDist2Sorted[0].x
    let xmax = xmin
    let ymin = pointsDist2Sorted[0].y
    let ymax = ymin

    let bestMatch = Number.MAX_VALUE
    const n = pointsDist2Sorted.length
    let n4 = n >>> 2
    if (n4 < 2)
        n4 = 2
    for (let i = 1; i < n; ++i) {
        const x = pointsDist2Sorted[i].x
        const y = pointsDist2Sorted[i].y
        if (x < xmin) xmin = x
        if (x > xmax) xmax = x
        if (y < ymin) ymin = y
        if (y > ymax) ymax = y

        if (i < n4)
            continue

        let dist2 = pointsDist2Sorted[i].dist2
        if (dist2 < minMatchDist2)
            dist2 = minMatchDist2

        const match = dist2 / (i * Math.max(xmax - xmin, ymax - ymin))
        if (match < bestMatch)
            bestMatch = match
    }
    return bestMatch
}

function detectLine(points) {
    const bb = findBoundingBox(points)
    const bbDiag2 = pointPointDist2(bb[0], bb[1])
    const candidateLen2Thr = bbDiag2 * 1e-3
    const minMatchDist2 = bbDiag2 * 1e-8

    let bestCandidate
    let bestCandidateMatch = Number.MAX_VALUE

    const np = points.length
    for (let i1 = 0; i1 < (np - 1); ++i1) {
        for (let i2 = i1 + 1; i2 < np; ++i2) {
            const p1 = points[i1]
            const p2 = points[i2]
            const dist2 = pointPointDist2(p1, p2)
            if (dist2 < candidateLen2Thr)
                continue

            const candidate = Line.fromPoints(p1, p2)
            const match = calculateCandidateMatch(candidate, points, minMatchDist2)
            if (match < bestCandidateMatch) {
                bestCandidate = candidate
                bestCandidateMatch = match
            }
        }
    }

    return bestCandidate
}


const detectLineTests = [
    // 3 points of x - y + 2 = 0 followed by 2 points of x - y = 0
    [
        {x:3, y:5}, {x:4, y:6}, {x:5, y:7},
        {x:1, y:1}, {x:2, y:2}
    ],
    // 4 points of 2x + y - 14 = 0 followed by 9 points clustered around (3,3)
    [
        {x:6, y:2}, {x:5, y:4}, {x:4, y:6}, {x:3, y:8},
        {x:2.9, y:3.1}, {x:3, y:3.1}, {x:3.1, y:3.1}, {x:2.9, y:3}, {x:3, y:3}, {x:3.1, y:3}, {x:2.9, y:2.9}, {x:3, y:2.9}, {x:3.1, y:2.9}
    ],
    // 3 random points followed by 5 points of y = 5 with a bit of noise applied
    [
        {x:3, y:6}, {x:8, y:1}, {x:4, y:2},
        {x:1.1, y:5.02}, {x:2.9, y:4.92}, {x:5.2, y:4.99}, {x:7.2, y:5.03}, {x:8.5, y:4.96}
    ]
]


//
// image filtering
//


function toUint8(x) {
    return x < 0 ? 0 : x > 255 ? 255 : x | 0
}

function toGrayscale(img) {
    const width = img.naturalWidth
    const height = img.naturalHeight
    const luma = new Uint8Array(width * height)

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    canvas.width = width
    canvas.height = height
    context.drawImage(img, 0, 0)
    const rgb = context.getImageData(0, 0, width, height)
    for (let i = 0; i < rgb.data.length; i += 4) {
        const r = rgb.data[i]
        const g = rgb.data[i + 1]
        const b = rgb.data[i + 2]

        // BT.709 conversion coeffs
        const pixLuma = 0.2126 * r + 0.7152 * g + 0.0722 * b
        luma[i / 4] = toUint8(pixLuma)
    }
    canvas.remove()

    return luma
}

function extractEdgePoints(luma, width, height, edgeGuides, guideErrorTolerance) {
    const WEIGHT_CUTOFF = 0.1

    const distPenalty = []
    edgeGuides.forEach((edge) => {
        distPenalty.push(guideErrorTolerance * 100 / edge.length2())
    })

    const edgePoints = [[], [], [], []]
    const KERNEL = 7
    const KERNEL_SIZE = KERNEL * 2
    for (let y = KERNEL; y < height - KERNEL + 1; ++y) {
        for (let x = KERNEL; x < width - KERNEL + 1; ++x) {
            let vf = 0, hf = 0
            for (let ky = -KERNEL; ky < KERNEL; ++ky) {
                for (let kx = -KERNEL; kx < KERNEL; ++kx) {
                    const i = (y + ky) * width + (x + kx)
                    if (kx === -1)
                        vf += luma[i]
                    else if (kx === 0)
                        vf -= luma[i]

                    if (ky === -1)
                        hf += luma[i]
                    else if (ky === 0)
                        hf -= luma[i]
                }
            }

            // vertical and horizontal filter results
            vf = Math.sqrt(Math.abs(vf / KERNEL_SIZE)) >>> 1
            hf = Math.sqrt(Math.abs(hf / KERNEL_SIZE)) >>> 1

            let point = new Point(x, y)
            if (vf > 0) {
                for (let dir = LEFT; dir <= RIGHT; ++dir) {
                    const guideDist = pointLineSegmentDist2(point, edgeGuides[dir])
                    const weight = Math.exp(-guideDist * distPenalty[dir]) * vf
                    if (weight > WEIGHT_CUTOFF)
                        edgePoints[dir].push({x, y, weight})
                }
            }

            if (hf > 0) {
                for (let dir = TOP; dir <= BOTTOM; ++dir) {
                    const guideDist = pointLineSegmentDist2(point, edgeGuides[dir])
                    const weight = Math.exp(-guideDist * distPenalty[dir]) * hf
                    if (weight > WEIGHT_CUTOFF)
                        edgePoints[dir].push({x, y, weight})
                }
            }
        }
    }

    // we don't want too many points, but at the same time we don't want to cut off
    // low contrast points that might be important
    const maxVertPoints = height << 2
    for (let dir = LEFT; dir <= RIGHT; ++dir) {
        if (edgePoints[dir].length > maxVertPoints) {
            edgePoints[dir].sort((a, b) => b.weight - a.weight)
            edgePoints[dir].splice(maxVertPoints)
        }
    }

    const maxHorzPoints = width << 2
    for (let dir = TOP; dir <= BOTTOM; ++dir) {
        if (edgePoints[dir].length > maxHorzPoints) {
            edgePoints[dir].sort((a, b) => b.weight - a.weight)
            edgePoints[dir].splice(maxHorzPoints)
        }
    }

    return edgePoints
}

function pickNPoints(points, n) {
    let totalWeight = 0
    points.forEach((item, i) => {
        totalWeight += item.weight
        points[i].cdf = totalWeight
    })

    const np1 = points.length - 1
    const picked = []
    for (let p = 0; p < n; ++p) {
        const rnd = Math.random() * totalWeight

        let idx = 0
        while (idx < np1) {
            if (rnd <= points[idx].cdf)
                break

            ++idx
        }

        picked.push({x: points[idx].x, y: points[idx].y})
    }
    return picked
}

//
// linear regression (https://en.wikipedia.org/wiki/Deming_regression#Orthogonal_regression but with weights,
//                    can probably be called Deming-Ivanov regression)
//

function calculateSolution(mx, my, sxx, sxy, syy, maxab) {
    const a = syy - sxx + Math.sqrt((syy - sxx) * (syy - sxx) + 4 * sxy * sxy)
    const b = -2 * sxy
    const c = 2 * sxy * my - a * mx
    maxab[0] = Math.max(Math.abs(a), Math.abs(b))
    return new Line(a, b, c)
}

function normalizeSolution(rl, maxab) {
    const norm = (rl.a < 0 ? -1 : 1) / maxab[0]
    rl.a *= norm
    rl.b *= norm
    rl.c *= norm
}

function fitRegressionLine(points, regressionLineCandidate, distTolerance) {
    const n = points.length

    let sw = 0
    for (let i = 0; i < n; ++i) {
        const dist2 = pointLineDist2(points[i], regressionLineCandidate)
        const w = Math.exp(-dist2 * distTolerance)
        points[i].weight = w
        sw += w
    }
    const swi = 1 / sw

    let mx = 0, my = 0
    for (let i = 0; i < n; ++i) {
        const w = points[i].weight
        const x = points[i].x
        const y = points[i].y
        mx += x * w
        my += y * w
    }

    mx *= swi
    my *= swi

    let sxx = 0, sxy = 0, syy = 0
    for (let i = 0; i < n; ++i) {
        const w = points[i].weight
        const x = points[i].x
        const y = points[i].y
        sxx += (x - mx) * (x - mx) * w
        sxy += (x - mx) * (y - my) * w
        syy += (y - my) * (y - my) * w
    }

    sxx *= swi
    sxy *= swi
    syy *= swi

    const maxab = []
    const rl = calculateSolution(mx, my, sxx, sxy, syy, maxab)

    // x and y flipped in order to handle vertical and horizontal lines
    const maxabf = []
    const rlf = calculateSolution(my, mx, syy, sxy, sxx, maxabf)

    if (maxab[0] > maxabf[0]) {
        normalizeSolution(rl, maxab)
        return rl
    }

    [rlf.a, rlf.b] = [rlf.b, rlf.a]
    normalizeSolution(rlf, maxabf)
    return rlf
}

//
// end of linear regression
//

export function findCornerPoints(luma, width, height, approxCornerPoints = null) {
  //const luma = toGrayscale(img_src)

    let edgeGuides = []
    if (approxCornerPoints) {
        const p = approxCornerPoints
        edgeGuides.push(new LineSegment(p[TOP_LEFT], p[BOTTOM_LEFT]))
        edgeGuides.push(new LineSegment(p[TOP_RIGHT], p[BOTTOM_RIGHT]))
        edgeGuides.push(new LineSegment(p[TOP_LEFT], p[TOP_RIGHT]))
        edgeGuides.push(new LineSegment(p[BOTTOM_LEFT], p[BOTTOM_RIGHT]))
    }
    else {
        const w16 = width >>> 4
        const h16 = height >>> 4
        edgeGuides.push(new LineSegment({x: w16, y: h16}, {x: w16, y: height - h16}))
        edgeGuides.push(new LineSegment({x: width - w16, y: h16}, {x: width - w16, y: height - h16}))
        edgeGuides.push(new LineSegment({x: w16, y: h16}, {x: width - w16, y: h16}))
        edgeGuides.push(new LineSegment({x: w16, y: height - h16}, {x: width - w16, y: height - h16}))
    }

    const edgePoints = extractEdgePoints(luma, width, height, edgeGuides, approxCornerPoints ? 10 : 1)

    const edges = []
    const EXTRACT_SET_SIZE = 75
    for (let edge = 0; edge < 4; ++edge) {
        const candidatePoints = pickNPoints(edgePoints[edge], EXTRACT_SET_SIZE)
        const line = detectLine(candidatePoints)
        line.normalize()
        const refinedEdge = fitRegressionLine(edgePoints[edge], line, 100000 / edgeGuides[edge].length2())
        edges.push(refinedEdge)
    }

    const corners = []
    corners.push(findIntersection(edges[LEFT], edges[TOP]))
    corners.push(findIntersection(edges[RIGHT], edges[TOP]))
    corners.push(findIntersection(edges[RIGHT], edges[BOTTOM]))
    corners.push(findIntersection(edges[LEFT], edges[BOTTOM]))

    // when we filter pixels we get edge coordinates one pix outside the right and bottom edges
    // (as compared to the left and top ones), so we need to adjust for that
    corners[TOP_RIGHT].x -= 1
    corners[BOTTOM_RIGHT].x -= 1
    corners[BOTTOM_RIGHT].y -= 1
    corners[BOTTOM_LEFT].y -= 1

    return corners
}
