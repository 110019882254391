import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { EmbedWMDemoComponent } from './views/embed/embed.component'
import { ExtractComponent } from './views/extract/extract.component'
import { UploadContentComponent } from './views/upload/upload.component'
import { OverviewComponent } from './views/overview/overview.component'
import { HelpComponent } from './views/help/help.component'
import {OrganizationSwitcherComponent} from "./organization-switcher/organization-switcher.component";

const routes: Routes = [
  {
    path: '',
    data: {
      breadcrumb: null,
      headline: 'Single-frame forensic watermarking demo'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: OverviewComponent,
        title: 'Single-frame forensic watermarking demo',
        data: {
          breadcrumb: null,
          headline: 'Single-frame forensic watermarking demo'
        }
      },
      {
        path: 'uploadcontent',
        pathMatch: 'full',
        component: UploadContentComponent,
        title: 'Upload content',
        data: {
          breadcrumb: 'Upload',
          headline: 'Upload'
        }
      },
      {
        path: 'embedwm',
        pathMatch: 'full',
        component: EmbedWMDemoComponent,
        title: 'Embed watermark',
        data: {
          breadcrumb: 'Embed',
          headline: 'Embed'
        }
      },
      {
        path: 'screencapture',
        pathMatch: 'full',
        component: ExtractComponent,
        title: 'Extract watermark',
        data: {
          breadcrumb: 'Extract',
          headline: 'Extract'
        }
      },
      {
        path: 'organization-switcher',
        pathMatch: 'full',
        component: OrganizationSwitcherComponent,
        title: 'Select Organization',
        data: {
          breadcrumb: 'Select Organization',
          headline: 'Select Organization'
        }
      },
      {
        path: 'help',
        pathMatch: 'full',
        component: HelpComponent,
        title: 'Help & best practices',
        data: {
          breadcrumb: 'Help',
          headline: 'Help & best practices'
        }
      }
    ]
  }
] // Single-frame\u00A0Watermarking:

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
