<input type="checkbox" id="cl-nav-side" class="cl-nav-checkbox" checked="checked">
<nav class="cl-nav-side">
  <label class="cl-nav-backdrop" for="cl-nav-side"></label>
  <div class="cl-nav-drawer">
    <div data-simplebar="data-simplebar">

      <div class="cl-nav-user" *ngIf="organizations">
        <a href="#">{{ this.user }}</a>
        <!-- <a href="#" class="cl-nav-icon"><i class="fas fa-bell" title="Notifications"></i></a> -->
      </div>
      <a class="cl-nav-organization" [routerLink]="['organization-switcher']" routerLinkActive="active"
         *ngIf="organizations">
        <label>Organization</label>
        <div class="cl-nav-organization-name">
          <p>{{ orgSelected | async }}</p>
        </div>
      </a>
      <div class="cl-nav-user" *ngIf="!organizations">
        <a href="#">Public Demo</a>
        <!-- <a href="#" class="cl-nav-icon"><i class="fas fa-bell" title="Notifications"></i></a> -->
      </div>
      <a class="login" *ngIf="!organizations">
        <button class="btn cl-btn-primary" style="margin-top: 16px; margin-left: 32px; padding-left: 64px; padding-right: 64px" (click)="login()"><span>Login</span><i class="fas fa-angle-right"></i></button>
      </a>
      <div class="cl-dropdown-arrow"></div>

      <a
        class="cl-nav-item"
        [routerLink]="['/']"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        Overview
        <p class="cl-nav-item-info">Start here.</p>
      </a>

      <hr class="cl-nav-separator" *ngIf="organizations">

      <a class="cl-nav-item" *ngIf="organizations" [routerLink]="['/', 'uploadcontent']" [routerLinkActive]="['active']">
        Upload
        <p class="cl-nav-item-info">Optionally use your own source content.</p>
      </a>
      <a class="cl-nav-item" *ngIf="organizations" [routerLink]="['/', 'embedwm']"
         [routerLinkActive]="['active']">
        Embed
        <p class="cl-nav-item-info">Embed a watermark with custom parameters.</p>
      </a>
      <a class="cl-nav-item" *ngIf="organizations" [routerLink]="['/', 'screencapture']"
         [routerLinkActive]="['active']">
        Extract
        <p class="cl-nav-item-info">Extract the embedded watermark.</p>
      </a>

      <hr class="cl-nav-separator">

      <a class="cl-nav-item" [routerLink]="['/', 'help']"
         [routerLinkActive]="['active']">
        Help &amp; best practices
      </a>
      <a class="cl-nav-item cl-a-external" href="https://castlabs.com/image-watermarking/">Learn more </a>

      <hr class="cl-nav-separator">
      <a href="#" *ngIf="organizations" (click)="logout()" role="button" class="cl-nav-item cl-faded"> Sign out <i
        class="fas fa-sign-out-alt"></i></a>
      <div class="cl-spacing-medium"></div>
      <div style="flex-grow: 1"></div>
      <a href="#" *ngIf="(updateService.available | async) as available" (click)="reload()" role="button" class="cl-nav-item cl-faded" >Build {{ available.buildDate }} available
        <p class="cl-nav-item-info" *ngIf="available.isDirty">dirty {{ available.buildDate }}</p>
        <p class="cl-nav-item-info" *ngIf="!available.isDirty"> {{ available.buildDate }} {{ available.branch }} ({{ available.commit }})</p></a>
    </div>
  </div>
</nav>

<div class="cl-main">
  <nav class="cl-nav-top">
    <div class="container">
      <div class="row">
        <div class="col-12 cl-grid-flex-centered">
          <label class="cl-nav-side-burger" for="cl-nav-side">
            <i class="fas fa-bars"></i>
          </label>
          <app-breadcrumb></app-breadcrumb>
          <!-- <div class="cl-path">
            <span><a href="index.html">Boilerplate</a></span>
            <span><a href="index.html">Side-nav</a></span>
          </div> -->
          <div class="cl-logo cl-grid-flex-centered">
            <span><!-- ENV --></span>
            <a href="https://castlabs.com/" target="_blank" rel="noopener"><i class="cl-asset-logo"></i></a>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <!-- ===================================================================== -->

  <main>
    <section class="cl-section-primary cl-section-headline">
      <div class="container cl-container-spacing-small">
        <div class="row">
          <div class="col-12">
            <app-headline></app-headline>
          </div>
        </div>
      </div>
    </section>

    <div *ngIf="organizations">
      <router-outlet></router-outlet>
    </div>
    <div *ngIf="!organizations">
      <app-overview-public></app-overview-public>
    </div>
  </main>

  <footer class="cl-footer cl-footer-white">
    <nav class="container">
      <div class="row">
        <div class="col-12 text-center">
          <p>
            <ng-container *ngIf="!version.isDirty">
            Version: {{ version.buildDate }} {{ version.branch }} ({{ version.commit }})
            ·
            </ng-container>
            <ng-container *ngIf="version.isDirty">
            Version: dirty ({{ version.buildDate }})
            ·
            </ng-container>
            <a href="https://castlabs.com/privacy-cookie-policy/">Privacy &amp; cookie policy</a>
            ·
            <a href="https://castlabs.com/legal/">Legal</a>
            · ©2008–2024 castLabs
          </p>
        </div>
      </div>
    </nav>
  </footer>
</div>
