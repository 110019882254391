import {Component, OnInit} from '@angular/core';
import {OAuthService} from "angular-oauth2-oidc";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {FWMState, reset, set} from "../store/FWMState";
import {Observable, of} from "rxjs";

@Component({
  selector: 'app-organization-select',
  templateUrl: './organization-switcher.component.html',
  styleUrls: ['./organization-switcher.component.scss']
})
export class OrganizationSwitcherComponent implements OnInit {
  organizations$: Observable<{
    organization_urn: string,
    display_name: string
  }[]>;


  constructor(private router: Router, private store: Store<{ state: FWMState }>) {
    this.organizations$ = this.store.select(state => {
      console.log('State', state.state.organizations);
      return Object.assign([], state.state.organizations)
    })
  }

  ngOnInit(): void {

  }

  switchTo(org: {
    organization_urn: string,
    display_name: string
  }) {
    console.log('Switching to organization', org);
    this.store.dispatch(reset())
    this.store.dispatch(set(({organization: org})))
    this.router.navigate(['/'])
  }

}
