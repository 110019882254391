import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {
  distinctUntilChanged,
  filter,
  lastValueFrom,
  map,
  mergeMap,
  Observable,
  shareReplay, switchMap,
  take,
  tap,
  timer
} from 'rxjs'
import {environment} from '../environments/environment'
import {AwsCredentialIdentityProvider} from "@smithy/types";
import {Store} from "@ngrx/store";
import {FWMState} from "./store/FWMState";


export interface Config {
  Path: string
  Bucket: string
  AccessKeyId: string
  SecretAccessKey: string
  SessionToken: string
  Expiration: string
  Organization: {
    organization_urn: string
    display_name: string

  }
  Organizations: {
    'organization_urn': string
    'display_name': string
  }[]
}


@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  config!: Config;

  config$: Observable<Config>;

  constructor(private http: HttpClient, private store: Store<{ state: FWMState }>) {

    const newOrgConfig: Observable<Config> = this.store.select(state => state.state.organization.organization_urn).pipe(
      distinctUntilChanged(),
      switchMap(() => {
        console.log('Refreshing config');
        return this.http.get<Config>(`${environment.baseurl}/assets/config.json`);
      })
    );


    this.config$ = timer(0, 60 * 1000).pipe(
      filter(() => {
        if (this.config) {
          const td = Date.parse(this.config.Expiration) - new Date().getTime();
          console.log(`${td} > ${60 * 1000} ${td < 60 * 1000} `);
          return td < 60 * 1000;
        } else {
          return true;
        }
      }),
      mergeMap(() => newOrgConfig),
      tap(v => this.config = v),
      shareReplay(1),
    )
  }


  credentials(): AwsCredentialIdentityProvider {

    return async () => {
      return lastValueFrom(this.config$.pipe(map(c => {
            console.log('credentials', c)
            return {
              accessKeyId: c.AccessKeyId,
              secretAccessKey: c.SecretAccessKey,
              sessionToken: c.SessionToken,
              expiration: new Date(Date.parse(c.Expiration))
            };
          }
        ),
        take(1)));
    }
  }
}
