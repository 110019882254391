<section class="cl-section-regular">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-8">
        <p class="cl-p-large">
          Upload a video or image file to be used during watermark embedding.
        </p>
        <p>
          If you don't want to add your own content, you can
          <a [routerLink]="['/', 'embedwm']">use one of our stock videos</a>
          instead.
        </p>
        <p class="alert cl-alert-info">
          All FFmpeg compatible video formats are supported.
        </p>
        <p class="alert cl-alert-info">
          JPG, PNG, and TIF images formats are supported.
        </p>
      </div>
      <div class="col-12 col-lg-4" *ngIf="!state.processing && state.step>=5">
        <div class="cl-flex-center-center">
          <button class="btn btn-sm cl-btn-outline-text" role="button" (click)="restart()">
            <span>New upload</span>
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="cl-section-alternate" *ngIf="!state.processing && state.step<5">
  <div class="container">
    <div class="row text-center">
      <div class="col-12 col-md-6" *ngIf="state.step === 1">
        <input type="file" #fileinput id="input-file" name="file" accept="video/*, image/jpg, image/jpeg, image/png, image/tiff, image/tif" style="display: none" (change)="onFileSelected($event)">

        <div appDnd (fileDropped)="onFileDropped($event)">
          <div class="cl-dropzone cl-flex-center-center" style="min-height: 6em;">
            <span><i class="fas fa-file-upload"></i> Drop or <a (click)="fileinput.click()">browse</a> to add your files.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="cl-section-alternate" *ngIf="state.processing && state.step<5">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ul class="cl-wizard-steps">
          <li [class.active]="state.step === 1" class="auto-transition">
            <ng-container *ngIf="state.step === 1; else step1inactive">Initializing</ng-container>
            <ng-template #step1inactive>Initializing</ng-template>
          </li>
          <li [class.active]="state.step === 2" class="auto-transition">
            <ng-container *ngIf="state.step === 2; else step2inactive">Uploading</ng-container>
            <ng-template #step2inactive>Uploading</ng-template>
          </li>
          <li [class.active]="state.step === 3" class="auto-transition">
            <ng-container *ngIf="state.step === 3; else step3inactive">Transcoding</ng-container>
            <ng-template #step3inactive>Transcoding</ng-template>
          </li>
          <li [class.active]="state.step === 4" class="auto-transition">
            <ng-container *ngIf="state.step === 4; else step4inactive">Extracting feature points</ng-container>
            <ng-template #step4inactive>Extracting feature points</ng-template>
          </li>
          <li [class.active]="state.step === 5">
            Done
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section class="cl-section-regular" *ngIf="this.state.step === 5">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2>Upload complete</h2>
        <p>You can now <a href="{{ this.state.embedding_url }}">embed a watermark</a>.</p>
      </div>
    </div>
  </div>
  <p class="alert cl-version-info">
    Uploader version: {{ state.version }}
  </p>
</section>

<section class="cl-section-regular" *ngIf="this.state.error">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2>Error</h2>
        <p>{{ this.state.error }} </p>
      </div>
    </div>
  </div>
</section>
