<section class="cl-section-regular" *ngIf="state.step < 5 || state.processing">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-8">
        <p class="cl-p-large">
          Upload a content screenshot or camera photo and our cloud service will extract the watermark.
        </p>
        <p class="alert cl-alert-info">
          You'll need to replicate the settings selected during the embedding phase to
          accurately retrieve the watermark.
        </p>
        <p class="alert cl-alert-info">
          Extraction will start after selecting an image.
        </p>
        <p class="alert cl-alert-info">
          A secret key is used for watermark access. For production this is unique per-customer. For this demo it's fixed as: castLabs-demo
        </p>
      </div>
    </div>
  </div>
</section>

<section class="cl-section-alternate" *ngIf="!state.processing && state.step <= 1">
  <form class="container cl-form">
    <div class="row">
      <div class="col-12">
        <div class="cl-tabs">
          <input class="cl-tab-1" type="radio" id="tab-1" name="tabs-1" [checked]="!blind">
          <input class="cl-tab-2" type="radio" id="tab-2" name="tabs-1"[checked]="blind">
          <ul>
            <li class="cl-tab-1"><label for="tab-1" (click)="setupNonBlind()">Non-blind extraction</label></li>
            <li class="cl-tab-2"><label for="tab-2" (click)="setupBlind()">Blind extraction</label></li>
          </ul>
          <div class="cl-tab-1 cl-tab-content">
            <p>
              Non-blind extraction is generally the most reliable and robust as there's a copy of unmarked source content available to compare an image against.
            </p>
            <div class="container">
              <div class="row">
                <div class="col-12 col-md-8">
                  <label for="t1_file_input" class="form-label">Source content</label>
                  <select id="t1_file_input" class="form-select" [(ngModel)]="content_name" [ngModelOptions]="{standalone: true}">
                    <option *ngFor="let f of files" [value]="f" [selected]="content_name === f">{{f}}</option>
                  </select>
                </div>
                <div class="col-12 col-md-4">
                  <label for="t1_bit-profile" class="form-label">Number of bits embedded</label>
                  <select name="t1_bit-profile" id="t1_bit-profile" class="form-select" [(ngModel)]="bit_profile"
                          [ngModelOptions]="{standalone: true}" [disabled]="content_name === content_name_none">
                    <option *ngFor="let b of bit_profiles" [value]="b.id" [selected]="bit_profile === b.id">{{b.name}}</option>
                  </select>
                </div>

                <div class="col-12 col-md-4">
                  <label for="t1_profile_name_input" class="form-label">Use-case template</label>
                  <select id="t1_profile_name_input" class="form-select" [(ngModel)]="profile_idx"
                          [ngModelOptions]="{standalone: true}" (change)="onWatermarkProfileSelectionChange($event)"
                          [disabled]="content_name === content_name_none">
                    <option *ngFor="let p of profile_name" [value]="p.id" [selected]="profile_idx === p.id">{{p.name}}</option>
                  </select>
                </div>
                <div class="col-12 col-md-4">
                  <label for="t1_wm_strength_input" class="form-label">Watermark strength</label>
                  <select id="t1_wm_strength_input" class="form-select" [(ngModel)]="wm_strength"
                          [ngModelOptions]="{standalone: true}" [disabled]="content_name === content_name_none || profile_idx !== '2'">
                    <option *ngFor="let s of strengths" [value]="s.id" [selected]="wm_strength === s.id">{{s.name}}</option>
                  </select>
                </div>
                <div class="col-12 col-md-4">
                  <label for="t1_sp_density_input" class="form-label">Superpixel density</label>
                  <select id="t1_sp_density_input" class="form-select" [(ngModel)]="sp_density"
                          [ngModelOptions]="{standalone: true}" [disabled]="content_name === content_name_none || profile_idx !== '2'">
                    <option *ngFor="let d of densities" [value]="d.id" [selected]="sp_density === d.id">{{d.name}}</option>
                  </select>
                  <p class="valid-feedback">Use 100 if unkown.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="cl-tab-2 cl-tab-content">
            <p>
              Blind extraction covers use cases where there's no available copy of unmarked source content (for example with real-time communication).
            </p>
            <div class="container">
              <div class="row">
                <div class="col-12 col-md-4">
                  <label for="t2_bit-profile" class="form-label">Number of bits embedded</label>
                  <select name="t2_bit-profile" id="t2_bit-profile" class="form-select" [(ngModel)]="bit_profile"
                          [ngModelOptions]="{standalone: true}">
                    <option *ngFor="let b of bit_profiles" [value]="b.id" [selected]="bit_profile === b.id">{{b.name}}</option>
                  </select>
                </div>
                <div class="col-12 col-md-4">
                  <label for="t2_wm_strength_input" class="form-label">Watermark strength</label>
                  <select id="t2_wm_strength_input" class="form-select" [(ngModel)]="wm_strength"
                          [ngModelOptions]="{standalone: true}">
                    <option *ngFor="let s of strengths" [value]="s.id" [selected]="wm_strength === s.id">{{s.name}}</option>
                  </select>
                </div>
                <div class="col-12 col-md-4">
                  <label for="t2_sp_density_input" class="form-label">Superpixel density</label>
                  <select id="t2_sp_density_input" class="form-select" [(ngModel)]="sp_density"
                          [ngModelOptions]="{standalone: true}">
                    <option *ngFor="let d of densities" [value]="d.id" [selected]="sp_density === d.id">{{d.name}}</option>
                  </select>
                  <p class="valid-feedback">Use 100 if unkown.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row text-center">
      <div class="col-6 cl-spacing-medium">
        <input type="file" #fileinput id="input-file" name="file" accept="video/*, image/jpg, image/jpeg, image/png, image/tiff, image/tif" style="display: none" (change)="onFileSelected($event)">

        <div appDnd (fileDropped)="onFileDropped($event)">
          <div class="cl-dropzone cl-flex-center-center" style="min-height: 6em;">
            <span><i class="fas fa-file-upload"></i> Drop or <a (click)="fileinput.click()">browse</a> to select a screenshot.</span>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>

<section class="cl-section-alternate" *ngIf="state.processing">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ul class="cl-wizard-steps">
          <li [class.active]="state.step === 1" class="auto-transition">
            <ng-container *ngIf="state.step === 1; else step1inactive">Initializing</ng-container>
            <ng-template #step1inactive>Initializing</ng-template>
          </li>
          <li [class.active]="state.step === 2" class="auto-transition">
            <ng-container *ngIf="state.step === 2; else step2inactive">Uploading</ng-container>
            <ng-template #step2inactive>Uploading</ng-template>
          </li>
          <li [class.active]="state.step === 3" class="auto-transition">
            <ng-container *ngIf="state.step === 3; else step3inactive">Searching</ng-container>
            <ng-template #step3inactive>Searching</ng-template>
          </li>
          <li [class.active]="state.step === 4" class="auto-transition">
            <ng-container *ngIf="state.step === 4; else step4inactive">Refining</ng-container>
            <ng-template #step4inactive>Refining</ng-template>
          </li>
          <li [class.active]="state.step === 5">
            Done
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section *ngIf="(state.result || state.rectifiedFrame) && !state.error" class="cl-section-regular">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-8">
        <p class="cl-p-large">
          The watermark extraction has completed.
        </p>
      </div>
      <div class="col-12 col-lg-4">
        <div class="cl-flex-center-center">
          <button class="btn btn-sm cl-btn-outline-text" role="button" (click)="restart()">
            <span>New extraction</span>
            <i class="fas fa-redo-alt"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2>Extraction report</h2>
        <dl *ngIf="state.wm_id_dec; else nowatermark" class="cl-dl">
          <dt *ngIf="state?.adv_stats || (state?.min_value_numeric ?? 0) > 0.1">Watermark ID</dt>
          <dd *ngIf="state?.adv_stats || (state?.min_value_numeric ?? 0) > 0.1">
            {{ state.wm_id_dec }}<br>
            <small class="cl-faded">
              {{ state.wm_id_bin }} bin • {{ state.wm_id_hex }} hex
            </small>
          </dd>
          <dt>Confidence</dt>
          <dd>
            {{ state.min_value_numeric }}
            <span class="badge cl-badge-text wm-badge-yellow" [ngClass]="{
              'wm-badge-yellow': (state.min_value_numeric ?? 0) <= 0.1,
              'wm-badge-green' : (state.min_value_numeric ?? 0) >= 0.2
            }">{{ state.min_value_text }}</span>

            <p class="alert cl-alert-info cl-spacing-micro">
              On a scale from <em>not confident</em> to <em>very high confidence</em>.
            </p>
          </dd>
          <dt>Error correction code check</dt>
          <dd>
            {{ state.corrections }}
            <p class="alert cl-alert-info cl-spacing-micro">
              A parity bit is used to correct the least confident bit (every 8 bits).
            </p>
          </dd>
        </dl>

        <div *ngIf="!state?.adv_stats && (state?.min_value_numeric ?? 0) <= 0.1" class="card cl-spacing-medium">
          <div class="card-body">
            <p>
              The confidence level of this extraction is below the required threshold to determine a reliable ID match.
              Please contact the castLabs support team for assistance and guidance.
            </p>
          </div>
        </div>

        <ng-template #nowatermark><p>{{ state.result }}</p></ng-template>


        <ng-container *ngIf="state.frameNumber">
          <h3>Your uploaded image</h3>

          <p class="alert cl-alert-info">
            We've attempted to align the content corners to use for extraction.
            In most cases these will be identified automatically,
            but certain extraction conditions may cause unexpected results.
          </p>

          <details class="cl-dropdown">
            <summary>Content corners not correctly aligned? <i class="far fa-question-circle cl-faded"></i></summary>
            <p>
              If content corners haven't been correctly aligned automatically you can move the corner nodes into place. Corner nodes are displayed as circles. Select a node to highlight it and then select its new location. Alternatively, you can manually enter pixel location values below for each node. Once completed press <em>Refine extraction</em> to restart the extraction.
            </p>
            <p>
              If a corner node is in the vicinity of a content corner you can press <em>Align borders</em> and it will attempt to automatically find the correct corner location.
            </p>
          </details>

          <ng-container *ngIf="state.capturedFrame">
            <canvas #canvasCap style="width: 100%" class="cl-spacing-small"></canvas>
          </ng-container>

          <div class="container cl-form">
            <div class="row">
              <div class="col-6">
                <label>Top-left X,Y</label><br>
                <input type="number" id="tlx" min="0" [max]="canvasCap.nativeElement.width" [step]="xStep"
                       placeholder="0" [(ngModel)]="cornerPointsInt[0]"
                       [ngModelOptions]="{standalone: true}" (change)="onChange()" class="wm-input-small">
                <input type="number" id="tly" min="0" [max]="canvasCap.nativeElement.height" [step]="yStep"
                       placeholder="0" [(ngModel)]="cornerPointsInt[1]"
                       [ngModelOptions]="{standalone: true}" (change)="onChange()" class="wm-input-small">
              </div>
              <div class="col-6 text-end">
                <label style="text-align:end">Top-right X,Y</label><br>
                <input type="number" id="trx" min="0" [max]="canvasCap.nativeElement.width" [step]="xStep"
                       placeholder="0" [(ngModel)]="cornerPointsInt[2]"
                       [ngModelOptions]="{standalone: true}" (change)="onChange()" class="wm-input-small">
                <input type="number" id="try" min="0" [max]="canvasCap.nativeElement.height" [step]="yStep"
                       placeholder="0" [(ngModel)]="cornerPointsInt[3]"
                       [ngModelOptions]="{standalone: true}" (change)="onChange()" class="wm-input-small">
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label>Bottom-left X,Y</label><br>
                <input type="number" id="blx" min="0" [max]="canvasCap.nativeElement.width" [step]="xStep"
                       placeholder="0" [(ngModel)]="cornerPointsInt[4]"
                       [ngModelOptions]="{standalone: true}" (change)="onChange()" class="wm-input-small">
                <input type="number" id="bly" min="0" [max]="canvasCap.nativeElement.height" [step]="yStep"
                       placeholder="0" [(ngModel)]="cornerPointsInt[5]"
                       [ngModelOptions]="{standalone: true}" (change)="onChange()" class="wm-input-small">
              </div>
              <div class="col-6 text-end">
                <label style="text-align: end">Bottom-right X,Y</label><br>
                <input type="number" id="brx" min="0" [max]="canvasCap.nativeElement.width" [step]="xStep"
                       placeholder="0" [(ngModel)]="cornerPointsInt[6]"
                       [ngModelOptions]="{standalone: true}" (change)="onChange()" class="wm-input-small">
                <input type="number" id="bry" min="0" [max]="canvasCap.nativeElement.height" [step]="yStep"
                       placeholder="0" [(ngModel)]="cornerPointsInt[7]"
                       [ngModelOptions]="{standalone: true}" (change)="onChange()" class="wm-input-small">
              </div>
            </div>
          </div>

          <div class="cl-spacing-box text-center">
            <button class="btn cl-btn-outline-primary" [ngClass]="{
              'cl-btn-spinner': state.processing
            }" role="button" (click)="alignBorders()">
              <span>Align Borders</span>
              <i class="fas fa-angle-right"></i>
            </button>
            <button style="margin-right: 20px;" class="btn cl-btn-outline-primary" [ngClass]="{
              'cl-btn-spinner': state.processing
            }" role="button" (click)="refineExtraction()">
              <span>Refine extraction</span>
              <i class="fas fa-angle-right"></i>
            </button>
          </div>

          <p class="alert cl-alert-info">
            If you're seeing unexpected results, please ensure that the extraction settings match the
            values used during the embedding phase. If issues persist, please contact our team and
            we can offer manual extraction for greater precision.
          </p>

          <ng-container *ngIf="state.rectifiedFrame">
            <h3>Extraction details</h3>
            <canvas #canvasRec style="width: 100%" class="cl-spacing-small"></canvas>
            <dl class="cl-dl">
              <dt>Frame</dt>
              <dd>{{ state.frameNumber }}</dd>
              <dt>Date</dt>
              <dd>{{ getStartDate() }} UTC</dd>
              <dt>Duration</dt>
              <dd>{{ getDuration() }}</dd>
              <dt>Parameters</dt>
              <dd>Bits {{ state.bit_profile }}, Strength {{ state.wm_strength }}, Superpixel density {{ state.sp_density }}</dd>
              <dt>Extraction software version</dt>
              <dd>{{ state.version }}</dd>
            </dl>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</section>

<section *ngIf="state.error" class="cl-section-regular">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-8" *ngIf="isNoReferenceFrameFound(); else otherError">
        <p>
          No reference frame was found.
        </p>
        <p>
          {{ state.error }}
        </p>
        <p>
          This can occur when:
        </p>
        <ul class="cl-list">
          <li>The quality of an uploaded photo is too low for extraction. Please try a different photo.
          </li>
          <li>The incorrect Source content was selected for non-blind extraction.
            Please ensure you've selected the correct Source content value that
            matches the content of the image you're uploading.
          </li>
        </ul>
        <p>
          If you continue experiencing difficulties, please don't hesitate to contact us.
        </p>
      </div>
      <ng-template #otherError>
        <div class="col-12 col-lg-8">
          <p>
            An error occurred: {{ state.error }}
          </p>
        </div>
      </ng-template>
      <div class="col-12 col-lg-4">
        <div class="cl-flex-center-center">
          <button class="btn btn-sm cl-btn-outline-text" role="button" (click)="restart()">
            <span>New extraction</span>
            <i class="fas fa-redo-alt"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
