import {Component} from '@angular/core'
import {filter, Observable, tap} from "rxjs";
import {OAuthService} from "angular-oauth2-oidc";

@Component({
  selector: 'app-overview-public',
  templateUrl: './overview-public.component.html',
  styleUrls: ['./overview-public.component.scss']
})
export class OverviewPublicComponent {
  loggedIn = false;

  constructor() {
  }
}
