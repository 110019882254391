import {Component, OnInit} from '@angular/core'
import {S3} from '@aws-sdk/client-s3'
import {AppConfigService} from '../../app-config.service'
import {mergeMap, skipWhile, takeUntil, timer} from 'rxjs'
import {animate, state, style, transition, trigger} from '@angular/animations'
import {HttpClient} from '@angular/common/http'
import {environment} from '../../../environments/environment'
import {Subject} from 'rxjs/internal/Subject'
import {FWMState, initialState, set, StateUpdate} from "../../store/FWMState";
import {Store} from "@ngrx/store";

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('growshrink', [
      state(
        'void',
        style({
          opacity: 0,
          height: '0',
          padding: '0',
          margin: '0',
          border: '0',
          transform: 'matrix(0.0001,0,0,0.0001,-400,0)'
        })
      ),
      // element being added into DOM.
      transition(':enter', [
        animate(
          '300ms ease-in-out',
          style({
            opacity: '*',
            height: '*',
            padding: '*',
            margin: '*',
            border: '*',
            transform: 'matrix(1,0,0,1, 0,0)'
          })
        )
      ]),
      // element being removed from DOM.
      transition(':leave', [
        animate(
          '300ms ease-in-out',
          style({
            opacity: 0,
            height: '0',
            padding: '0',
            margin: '0',
            border: '0',
            transform: 'matrix(0.0001,0,0,0.0001,400,0)'
          })
        )
      ])
    ])]
})
export class UploadContentComponent implements OnInit {
  state = initialState.upload

  s3: S3
  result!: string
  processID!: string
  key!: string
  bucket!: string
  current_operation = 'Initialization'
  content_name = 'hockey'

  organization!: string;

  constructor(
    private appConfig: AppConfigService,
    private httpClient: HttpClient,
    private store: Store<{ state: FWMState }>
  ) {

    this.s3 = new S3({
      credentials: appConfig.credentials(),
      region: 'us-east-1'
    })
  }

  ngOnInit(): void {
    this.store.select('state').subscribe(data => {
      this.state = data.upload
      this.organization = data.organization.organization_urn
    })
  }

  updateState(data: StateUpdate['upload']): void {
    this.store.dispatch(set({upload: data}))
  }

  restart(): void {
    this.updateState(initialState.upload)
  }

  onFileDropped(files: File[]) {
    for (const file of files) {
      this.startUpload(file);
    }
  }

  onFileSelected(event: Event) {
    const input = event.currentTarget as HTMLInputElement;
    for (const file of Array.from(input.files || [])) {
      this.startUpload(file);
    }
  }

  startUpload(selectedFile: File) {
    this.updateState({
      step: 2,
      processing: true
    })


    const ext = selectedFile.name.split('.').pop()
    const stem = selectedFile.name.split('.').slice(0, -1).join('.')
    const uuid = crypto.randomUUID()
    this.key = this.organization + '/' + uuid + '/' + stem + '.' + ext
    const params = {
      Key: this.key,
      Body: selectedFile,
      Bucket: this.appConfig.config.Bucket
    }
    this.bucket = this.appConfig.config.Bucket
    this.s3.putObject(params).then(() => {

    }).catch(reason => {
      this.updateState({
        error: reason
      })
    })
    const json = {
      organization: this.organization,
      content_name: this.content_name,
      key: this.key,
      bucket: this.bucket
    }
    const stopPolling$ = new Subject()
    this.httpClient.post<string>(`${environment.baseurl}/upload`, json).pipe().subscribe({
      next: (res) => {
        timer(1000, 1000).pipe(
          takeUntil(stopPolling$),
          mergeMap(() => this.httpClient.get<{
            'status': string,
            'result': string,
            'process_id': string,
            'version': string,
            'current_operation': string,
            'error_cause': string
          }>(`${environment.baseurl}/upload/${res}`)),
          skipWhile(res2 => res2.status === 'STOPPED')
        ).subscribe({
          next: (res3) => {
            if (res3.status === 'SUCCEEDED') {
              stopPolling$.next(true)
              this.result = res3.result
              this.processID = res3.process_id
              this.current_operation = 'Finished'
              this.updateState({
                step: 5,
                processing: false,
                version: res3.version,
                embedding_url: window.location.origin + '/#/embedwm'
              })
            } else if (res3.status === 'RUNNING') {
              this.current_operation = res3.current_operation
              console.log(this.current_operation)
              switch (this.current_operation) {
                case 'Uploading an asset':
                  this.updateState({step: 2})
                  break
                case 'Transcoding to the storage format':
                  this.updateState({step: 3})
                  break
                case 'Processing features':
                  this.updateState({step: 4})
                  break
                case 'Cleaining up':
                  this.updateState({step: 5})
                  break
              }
            } else if (res3.status === 'FAILED') {
              stopPolling$.next(true)
              this.current_operation = 'Failed'
              this.updateState({
                step: 0,
                processing: false,
                error: res3.error_cause
              })
            }
          }
        })
      },
      error: err => {
        console.log(err)
        this.updateState({processing: false})
      }
    })
  }

  protected readonly environment = environment
}
