import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { filter } from 'rxjs'
import { animate, keyframes, style, transition, trigger } from '@angular/animations'

@Component({
  selector: 'app-headline',
  templateUrl: './headline.component.html',
  styleUrls: ['./headline.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger(
      'valueChanged',
      [
        transition('void => *', [animate('250ms 0ms ease-in', style({ transform: 'scale(0)' }))]), // when the item is created
        transition('* => void', []), // when the item is removed
        transition('* => *', [ // when the item is changed
          animate(100, keyframes([ // animate for 1200 ms
            style({ opacity: 0, offset: 0.0 }),
            style({ opacity: 1, offset: 1.0 })
          ]))
        ])
      ])]
})
export class HeadLineComponent implements OnInit {
  headline!: string

  router: Router
  activatedRoute: ActivatedRoute

  constructor (router: Router, activatedRoute: ActivatedRoute) {
    this.router = router
    this.activatedRoute = activatedRoute
  }

  ngOnInit (): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.headline = this.getHeadline(this.activatedRoute)
      })
  }

  private getHeadline (route: ActivatedRoute, headline: string = ''): string {
    const children: ActivatedRoute[] = route.children
    if (children.length === 0) {
      return headline
    }
    return this.getHeadline(children[0], children[0].snapshot.data.headline)
  }
}
