import {Component} from '@angular/core'
import {animate, keyframes, style, transition, trigger} from '@angular/animations'
import {OAuthService} from "angular-oauth2-oidc";
import {filter, Observable, take, tap} from "rxjs";
import {authConfig} from "./authConfig";
import {Store} from "@ngrx/store";
import {FWMState, set} from "./store/FWMState";
import {HttpClient} from "@angular/common/http";
import {environment} from "../environments/environment";
import {AppConfigService} from "./app-config.service";
import {UpdateService} from "./update.service";
import {version} from "../version";

interface Membership {
  roles: { role_urn: string }[];
  organization: { organization_urn: string, display_name: string };
}

interface Profile {
  email: string;
}

interface UserInfo {
  memberships: Membership[];
  profile: Profile;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger(
      'valueChanged',
      [
        transition('void => *', [animate('250ms 0ms ease-in', style({transform: 'scale(0)'}))]), // when the item is created
        transition('* => void', []), // when the item is removed
        transition('* => *', [ // when the item is changed
          animate(100, keyframes([ // animate for 1200 ms
            style({opacity: 0, offset: 0.0}),
            style({opacity: 1, offset: 1.0})
          ]))
        ])
      ])
  ]
})
export class AppComponent {
  display_name!:string
  title = 'upload-processing'
  organizations!: { organization_urn: string, display_name: string }[];
  orgSelected: Observable<string>;
  user: string = 'not logged in';
  version = version

  constructor(
    protected oAuthService: OAuthService,
    private store: Store<{ state: FWMState }>,
    httpClient: HttpClient,
    appConfigService: AppConfigService,
    protected updateService: UpdateService
  ) {
    this.oAuthService.configure(authConfig);
    this.oAuthService.loadDiscoveryDocument().then(() => {
      console.log('TRY LOGIN START')
      this.oAuthService.tryLogin()
      console.log('TRY LOGIN END')
    })
    this.oAuthService.setupAutomaticSilentRefresh()
    this.orgSelected = this.store.select(state => state.state.organization.display_name)

    this.oAuthService.events.pipe(
      tap((e) => console.debug('discovery_document_loaded || token_received', e)),
      filter((e) => e.type === 'discovery_document_loaded' || e.type === 'token_received'))
      .subscribe((_) => {
        if (this.oAuthService.hasValidAccessToken()) {
          httpClient.get<UserInfo>(environment.userinfo, {
            headers: {
              Authorization: `Bearer ${this.oAuthService.getAccessToken()}`
            }
          }).subscribe(u => {
            console.log('user', u)
            this.user = u['profile']['email']
            this.organizations = [];
            for (const membership of u.memberships) {
              const wmdemo = membership.roles.find(m => m.role_urn === "urn:janus:role:33c9c2001ca14dde9c3b310d0748b850");
              if (wmdemo) {
                this.organizations.push({
                  organization_urn: membership.organization.organization_urn,
                  display_name: membership.organization.display_name
                });
              }
            }
            if (this.organizations.length === 0) {
              alert('You are not a member of any organization')
            }
            this.store.dispatch(set({organization: this.organizations[0], organizations: [...this.organizations]}))
            console.log('organizations', this.organizations)
            console.log('orgSelected', this.orgSelected)
            console.log('user', this.user)
            appConfigService.config$.pipe(take(1)).subscribe()

          })
        }
      });

  }

  login() {
    this.oAuthService.initLoginFlow();
  }

  logout() {
    this.oAuthService.logOut()
  }

  reload() {
    location.reload()
  }
}
