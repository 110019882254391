import { Component, OnInit } from '@angular/core'
import { mergeMap, skipWhile, takeUntil, timer } from 'rxjs'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { DomSanitizer } from '@angular/platform-browser'
import { Subject } from 'rxjs/internal/Subject'
import { FormControl } from '@angular/forms'

import { Store } from '@ngrx/store'
import {FWMState, initialState, set, StateUpdate} from "../../store/FWMState";

@Component({
  selector: 'app-embed',
  templateUrl: './embed.component.html',
  styleUrls: ['./embed.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('growshrink', [
      state(
        'void',
        style({
          opacity: 0,
          height: '0',
          padding: '0',
          margin: '0',
          border: '0',
          transform: 'matrix(0.0001,0,0,0.0001,-400,0)'
        })
      ),
      // element being added into DOM.
      transition(':enter', [
        animate(
          '300ms ease-in-out',
          style({
            opacity: '*',
            height: '*',
            padding: '*',
            margin: '*',
            border: '*',
            transform: 'matrix(1,0,0,1, 0,0)'
          })
        )
      ]),
      // element being removed from DOM.
      transition(':leave', [
        animate(
          '300ms ease-in-out',
          style({
            opacity: 0,
            height: '0',
            padding: '0',
            margin: '0',
            border: '0',
            transform: 'matrix(0.0001,0,0,0.0001,400,0)'
          })
        )
      ])
    ])]
})

export class EmbedWMDemoComponent implements OnInit {
  files: string[] = []

  bit_profiles = [{
    id: '8',
    name: '8',
    max: '255'
  },
  {
    id: '13',
    name: '13',
    max: '8191'
  },
  {
    id: '16',
    name: '16',
    max: '65535'
  },
  {
    id: '24',
    name: '24',
    max: '16777215'
  },
  {
    id: '32',
    name: '32',
    max: '4294967295'
  }
  ]

  strengths = [
    // {
    //   'id': '2',
    //   'name': '2 - low robustness, low visibility'
    // },
    {
      id: '3',
      name: '3'
    },

    {
      id: '4',
      name: '4'
    },

    {
      id: '6',
      name: '6 (suitable for blind extraction)'
    },

    {
      id: '8',
      name: '8 (suitable for blind extraction)'
    }

  ]

  densities = [{
    id: '10',
    name: '10'
  },
  {
    id: '20',
    name: '20'
  },
  {
    id: '25',
    name: '25'
  },
  {
    id: '30',
    name: '30'
  },
  {
    id: '35',
    name: '35'
  },
  {
    id: '40',
    name: '40'
  },
  {
    id: '45',
    name: '45'
  },
  {
    id: '50',
    name: '50'
  },
  {
    id: '60',
    name: '60'
  },
  {
    id: '70',
    name: '70'
  },
  {
    id: '90',
    name: '90'
  },
  {
    id: '100',
    name: '100'
  }
  ]

  profiles = [
    { wm_strength: '4', sp_density: '30', pixel_density: '45' },
    { wm_strength: '4', sp_density: '60', pixel_density: '60' },
    { wm_strength: '4', sp_density: '60', pixel_density: '60' }
  ]

  profile_name = [
    {
      id: '0',
      name: 'Maintain high quality',
      type: 'pre-set'
    },
    {
      id: '1',
      name: 'Social media leak',
      type: 'pre-set'
    },
    {
      id: '2',
      name: 'Custom',
      type: 'set your own values'
    }
  ]

  key!: string
  bucket!: string

  method = 'non-blind'

  profile_idx = '2'
  content_name = 'hockey'
  wm_id_max = this.bit_profiles[1].max
  wmidInput = new FormControl('')

  state = initialState.embed
  wm_id = '0'

  organization!: string;

  constructor (
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    private store: Store<{ state: FWMState }>
  ) {
  }

  ngOnInit (): void {
    this.store.select('state').subscribe(data => {
      this.state = data.embed
      this.organization = data.organization.organization_urn
    })
    if (!this.state.videoLink) this.restart()
    this.httpClient.get<{
      'status': string,
      'content_list': string,
    }>(`${environment.baseurl}/get_content_list`).subscribe({
      next: (res) => {
        const contentList = res.content_list.split(';').map(String)
        for (let i = 0; i < contentList.length; i++) { // the last position is no reference
          this.files.push(contentList[i])
        }
      }
    })
  }

  updateState (data: StateUpdate['embed']): void {
    this.store.dispatch(set({ embed: data }))
  }

  restart (): void {
    this.updateState(initialState.embed)
    this.updateState({
      wm_id: '' + (Math.floor(Math.random() * parseInt(this.wm_id_max, 10))).toString(10),
      bit_profile: '13',
      wm_strength: this.profiles[1].wm_strength,
      sp_density: this.profiles[1].sp_density,
      pixel_density: this.profiles[1].pixel_density
    })
    this.wm_id = this.state.wm_id
  }

  onWatermarkProfileSelectionChange (event: Event) {
    const selectedId = (<HTMLSelectElement>event.target).value
    const selectedProfile = this.profile_name.find(b => b.id === selectedId)
    if (selectedProfile) {
      this.updateState({
        wm_strength: this.profiles[parseInt(selectedProfile.id, 10)].wm_strength,
        sp_density: this.profiles[parseInt(selectedProfile.id, 10)].sp_density,
        pixel_density: this.profiles[parseInt(selectedProfile.id, 10)].pixel_density
      })
    }
  }

  onBitProfileSelectionChange (event: Event) {
    const selectedId = (<HTMLSelectElement>event.target).value
    const selectedProfile = this.bit_profiles.find(b => b.id === selectedId)
    if (selectedProfile) {
      this.wm_id_max = selectedProfile.max
      this.limitInputValue()
    }
  }

  limitInputValue () {
    if (typeof this.wmidInput.value === 'string') {
      const inputValue = parseInt(this.wmidInput.value, 10)
      const maxVal = parseInt(this.wm_id_max, 10) //
      if (inputValue > maxVal) {
        this.wmidInput.setValue(this.wm_id_max)
      }
    }
  }

  setupNonBlind () {
    this.profile_idx = '2'
    this.method = 'non-blind'
    this.updateState({
      wm_strength: '4',
      sp_density: '60',
      pixel_density: '60'
    })
  }

  setupBlind () {
    this.method = 'blind'
    this.updateState({
      wm_strength: '6',
      sp_density: '50',
      pixel_density: '50'
    })
  }

  processFile () {

    this.updateState({ wm_id: this.wm_id })
    const ext = this.content_name.split('.').pop()?.toLowerCase() ?? '';
    // check if extension belongs to an image
    if (ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'tif' || ext === 'tiff' || ext === 'bmp') {
      this.updateState({ isImage: true })
      if (ext === 'tif' || ext === 'tiff') {
        this.updateState({ is_tiff: true })
      }
    }
    this.updateState({ processing: true })
    const json = {
      organization: this.organization,
      strength: this.state.wm_strength,
      content_name: this.content_name,
      wm_id: parseInt(this.state.wm_id).toString(16), // need to convert dec to hex and to string
      sp_density: this.state.sp_density,
      pixel_density: this.state.pixel_density,
      bit_profile: this.state.bit_profile
    }

    const stopPolling$ = new Subject()
    this.httpClient.post<string>(`${environment.baseurl}/embed`, json).pipe().subscribe({
      next: (res) => {
        timer(1000, 1000).pipe(
          takeUntil(stopPolling$),
          mergeMap(() => this.httpClient.get<{
            'status': string,
            'resultUrl': string,
            'version': string,
            'error_cause': string
          }>(`${environment.baseurl}/embed/${res}`)),
          skipWhile(res2 => res2.status === 'RUNNING')
        ).subscribe({
          next: (res3) => {
            if (res3.status === 'SUCCEEDED') {
              this.updateState({
                videoLink: this.sanitizer.bypassSecurityTrustUrl(res3.resultUrl),
                version: res3.version
              })

              stopPolling$.next(true)
            } else if (res3.status === 'FAILED') {
              this.updateState({ error: res3.error_cause })
              stopPolling$.next(true)
            }
          }
        })
      },
      error: err => {
        console.log(err)
        this.updateState({ processing: false })
      }
    })
  }

  getProfileName () {
    return this.profile_name.find((p) => p.id === this.profile_idx)?.name ?? '???'
  }

  protected readonly environment = environment
}
