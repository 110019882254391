<section class="cl-section-regular">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-8">
        <p class="cl-p-large">
          Try our watermarking technology for yourself.
          First upload video or image content, then embed a watermark into it.
          Finally, experience how our extraction can identify the content source.
          <a class="cl-a-external" target="_blank" rel="noopener noreferrer" href="https://castlabs.com/image-watermarking/">Learn more</a>
        </p>
      </div>
    </div>
  </div>
</section>
