import {createReducer, on, createAction, props} from '@ngrx/store'
import {SafeUrl} from '@angular/platform-browser'

export type FWMState = {
  organization: {
    organization_urn: string
    display_name: string
  },
  organizations: {
    organization_urn: string
    display_name: string
  }[],


  upload: {
    embedding_url?: string
    error?: string
    processing: boolean
    step: number
    version?: string
  },
  embed: {
    bit_profile: string
    error?: string
    is_tiff: boolean
    isImage: boolean
    pixel_density: string
    processing: boolean
    sp_density: string
    videoLink?: SafeUrl
    wm_id: string
    wm_strength: string
    version?: string
  },
  extract: {

    adv_stats?: boolean
    bit_profile?: number
    capturedFrame?: string
    confidence?: string
    cornerPoints?: string
    corrections?: string
    error?: string
    frameNumber?: number
    min_value_numeric?: number
    min_value_text?: string
    min_value?: string
    parameters?: string
    processID?: string
    processing: boolean
    rectifiedFrame?: string
    result?: string,
    sp_density?: number
    start_date?: string
    step: number
    stop_date?: string
    version?: string
    wm_id_bin?: string
    wm_id_dec?: string
    wm_id_hex?: string
    wm_strength?: number
  }
}

export type StateUpdate = { // same as State, but all fields optional
  organization?: {
    organization_urn: string
    display_name: string
  },
  organizations?: {
    organization_urn: string
    display_name: string
  }[],

  upload?: {
    embedding_url?: string
    error?: string
    processing?: boolean
    step?: number
    version?: string
  },
  embed?: {
    bit_profile?: string
    error?: string
    is_tiff?: boolean
    isImage?: boolean
    pixel_density?: string
    processing?: boolean
    sp_density?: string
    videoLink?: SafeUrl
    wm_id?: string
    wm_strength?: string
    version?: string
  },
  extract?: {
    adv_stats?: boolean
    bit_profile?: number
    capturedFrame?: string
    confidence?: string
    cornerPoints?: string
    corrections?: string
    error?: string
    frameNumber?: number
    min_value_numeric?: number
    min_value_text?: string
    min_value?: string
    parameters?: string
    processID?: string
    processing?: boolean
    rectifiedFrame?: string
    result?: string
    sp_density?: number
    start_date?: string
    step?: number
    stop_date?: string
    version?: string
    wm_id_bin?: string
    wm_id_dec?: string
    wm_id_hex?: string
    wm_strength?: number
  }
}

export const initialState: FWMState = {
  organization: {
    organization_urn: 'public',
    display_name: 'public'
  },
  organizations: [],

  upload: {
    step: 1,
    processing: false
  },
  embed: {
    processing: false,
    isImage: false,
    is_tiff: false,
    wm_id: '',
    bit_profile: '',
    wm_strength: '',
    sp_density: '',
    pixel_density: '',
    videoLink: undefined // undefined = step 1
  },
  extract: {
    step: 1,
    processing: false,
    error: undefined,
    result: undefined,
    rectifiedFrame: undefined
  }
}

export const set = createAction('[State Component] set', props<StateUpdate>())
export const reset = createAction('[State Component] reset')

export const state = createReducer(
  JSON.parse(JSON.stringify(initialState)),
  on(set, (state, values) => merge(state, values)),
  on(reset, () => JSON.parse(JSON.stringify(initialState)))
)

function merge(obj1: object, obj2: object): object { // deep-merge two data objects
  if (!obj1 || !obj2) return obj1 ?? obj2 ?? {}
  const merged: any = {...obj1, ...obj2} // eslint-disable-line @typescript-eslint/no-explicit-any
  for (const key of [
    ...Object.keys(obj1),
    ...Object.keys(obj2)
  ]) {
    if (typeof merged[key] === 'object') {
      merged[key] = merge((obj1 as any)[key], (obj2 as any)[key]) // eslint-disable-line @typescript-eslint/no-explicit-any
    }
  }
  return merged
}
