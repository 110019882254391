<section class="cl-section-regular">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <p class="cl-p-large">
          Please choose the organization you want to manage.
        </p>
      </div>
    </div>
  </div>
</section>
<section class="cl-section-regular">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <table class="cl-table-data">
          <colgroup>
            <col class="cl-col-id" style="width: 30%">
            <col class="cl-col-name" style="width: 70%">
            <col class="cl-col-actions" style="width: 100px">
          </colgroup>
          <thead>
          <tr>
            <th class="cl-p-tiny">ID</th>
            <th class="cl-p-tiny">Organization</th>
            <th class="cl-p-tiny">Actions</th>
          </tr>
          </thead>
          <tbody>

          <tr *ngFor="let organization of (organizations$ | async)">

            <td class="cl-td-id vtk-col-id">{{ organization.organization_urn }}</td>
            <td>
                <span class="cl-org">{{ organization.display_name }}
                  <a (click)="switchTo(organization)" role="button" class="cl-a-transparent"></a>
                </span>
            </td>
            <td class="cl-td-links">
              <a (click)="switchTo(organization)" role="button" class="cl-a-btn">Switch</a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>
