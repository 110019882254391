import {Component} from '@angular/core'
import {filter, Observable, tap} from "rxjs";
import {OAuthService} from "angular-oauth2-oidc";

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent {

  constructor(private oauthService: OAuthService) {
  }
}
