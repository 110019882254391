<section class="cl-section-regular">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2>What does each setting mean?</h2>
        <p>
          By experimenting with the settings, you can test watermark extraction under a number of different scenarios to find a setup that best works for your use-case.
        </p>

        <h3>Source content</h3>
        <p>
          During embedding, the watermark will be applied to this image or video. You may use our existing test content or you can upload your own. During extraction, this is the watermarked content where information will be retrieved.
        </p>
        <p class="alert cl-alert-info">
          “Hockey” is recommended for testing how visible the watermark is in various settings.
        </p>

        <h3>Use-case template</h3>
        <p>
          We recommend selecting a preset template for common use cases with ideal detection parameters.
        </p>
        <ul class="cl-list">
          <li><strong>Social media leak:</strong> Useful where a photo of watermarked content is posted to social media. Such leaks are often taken from a mobile device and have some type of distortion which renders detection impossible for competing watermark solutions.</li>
          <li><strong>Maintain high quality:</strong> Useful where the watermark shouldn't be noticed and robustness of the mark is less important. This is typically appropriate when content quality must remain very high.</li>
          <li><strong>Custom:</strong> Allows you to define your own values.</li>
        </ul>

        <h3>Number of bits embedded</h3>
        <p>
          Determines the range of unique watermark IDs available to embed.
          Increasing this value provides a larger amount of IDs to choose from.
          Decreasing this value lowers the amount of available IDs, but makes
          the watermark easier to extract (better robustness).
        </p>

        <h3>Watermark ID</h3>
        <p>
          The unique value included as the imperceptible forensic watermark.
          This number enables you to identify a specific user or piece of content.
        </p>
        <p class="alert cl-alert-info">
          The range of available IDs is determined by the number of bits embedded.
        </p>
        <p class="alert cl-alert-info">
          For commercial usage, using hexadecimals will also be an option.
        </p>

        <h3>Watermark strength</h3>
        <p>
          Determines the watermark's maximum deviation from the original pixel's
          luminance value. A higher value makes the watermark easier to extract
          (better robustness), but its visibility will be higher.
        </p>

        <h3>Superpixel density</h3>
        <p>
          A superpixel is a group of pixels that can contain the watermark.
          The superpixel density determines what percentage of an image or frame's
          total area will be embedded with superpixels. A higher value means more
          superpixels are placed, making the watermark easier to extract (better
          robustness), but visibility will be higher.
        </p>
        <ul class="cl-list">
          <li>100% means as many superpixels as possible will be embedded across an image or frame.</li>
          <li>50% means half of an image or frame's total area will contain superpixels. We randomize superpixel locations while selecting the best positions for imperceptibility. In the case of video embedding, this randomization is on a per-frame basis.</li>
        </ul>

        <h3>Pixel density</h3>
        <p>
          The percentage of pixels used for the watermark within a given superpixel.
          A higher value makes the watermark easier to extract (better
          robustness), but its visibility will be higher.
        </p>

        <h2>Extraction considerations & best practices</h2>
        <p>
          During extraction, a screenshot will provide reliable results, however,
          our solution is robust and will also detect watermarks under other real-world scenarios.
          For example, detection also occurs from just one phone camera photo of watermarked content.
        </p>
        <p>
          When taking a photo, you can test the watermark under various conditions:
        </p>
        <ul class="cl-list">
          <li>For the “Maintain high quality” template:
            <ul class="cl-list">
              <li>Straight on (this yields the best results even at low watermark visibility settings)</li>
              <li>Screenshot containing 100% of the content frame</li>
            </ul>
          </li>
          <li>For the “Social media leak” template as well as blind extractions:
            <ul class="cl-list">
              <li>At angles</li>
              <li>Cropping</li>
              <li>With backlight or a camera flash</li>
              <li>Poor resolution</li>
            </ul>
          </li>
        </ul>

        <p>
          For optimal results, at least 30% of the extraction image should contain
          the watermarked frame/image, and at least one corner of the watermarked
          content should be visible.
        </p>
      </div>
    </div>
  </div>
</section>
