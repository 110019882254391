import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { filter } from 'rxjs'
import { animate, keyframes, style, transition, trigger } from '@angular/animations'

interface BreadCrumb {
  label: string;
  url: string;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger(
      'valueChanged',
      [
        transition('void => *', [animate('250ms 0ms ease-in', style({ transform: 'scale(0)' }))]), // when the item is created
        transition('* => void', []), // when the item is removed
        transition('* => *', [ // when the item is changed
          animate(100, keyframes([ // animate for 1200 ms
            style({ opacity: 0, offset: 0.0 }),
            style({ opacity: 1, offset: 1.0 })
          ]))
        ])
      ])]
})
export class BreadCrumbComponent implements OnInit {
  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb'
  readonly home = { icon: 'pi pi-home', url: 'home' }
  breadCrumbs: BreadCrumb[] = []

  router: Router
  activatedRoute: ActivatedRoute

  constructor (router: Router, activatedRoute: ActivatedRoute) {
    this.router = router
    this.activatedRoute = activatedRoute
  }

  ngOnInit (): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => { this.breadCrumbs = this.createBreadcrumbs(this.activatedRoute.root) })
  }

  private createBreadcrumbs (route: ActivatedRoute, url: string = '', breadcrumbs: BreadCrumb[] = []): BreadCrumb[] {
    const children: ActivatedRoute[] = route.children

    if (children.length === 0) {
      return breadcrumbs
    }

    const child = children[0]
    const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/')
    if (routeURL !== '') {
      url += `/${routeURL}`
    }

    const label = child.snapshot.data[BreadCrumbComponent.ROUTE_DATA_BREADCRUMB]
    if (label !== null) {
      breadcrumbs.push({ label, url })
    }

    return this.createBreadcrumbs(child, url, breadcrumbs)
  }
}
